import React, { useEffect, useRef, useState } from 'react'
import { Card, Col ,Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useDataTable from '../../../../components/hooks/useDatatable';
import $ from "jquery";
import { useMyContext } from '../../../../Context/MyContextProvider';
import axios from 'axios';

const AgentReports = () => {
    const { api, authToken } = useMyContext();
    const [report, setReport] = useState();
    const listtableRef = useRef(null);

    const GetBookings = async () => {
        await axios
            .get(`${api}agent-report`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
            .then((res) => {
                setReport(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        GetBookings();
    }, []);

    const columns = useRef([
        {
            data: null, // No direct data mapping
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1 // Use index + 1 as OrderId
        },
        {
            data: 'agent_name',
            title: "Name",
        },
        {
            data: 'booking_count',
            title: "Total Bookings", // Updated title to reflect the total bookings
        },
        {
            data: 'today_booking_count',
            title: "Today Bookings", // Updated title to reflect the total bookings
        },
        {
            data: 'today_total_amount',
            title: "Today Collection", // Updated title to reflect the total bookings
            render: (data) =>  '₹'+data 
        },
        {
            data: 'total_UPI_bookings',
            title: "UPI Bookings",
        },
        {
            data: 'total_Cash_bookings',
            title: "Cash Bookings",
        },
        {
            data: 'total_Net_Banking_bookings',
            title: "Net Banking Bookings",
        },
        {
            data: 'total_UPI_amount',
            title: "UPI Amount",
            render: (data) =>  '₹'+data 
        },
        {
            data: 'total_Cash_amount',
            title: "Cash Amount",
            render: (data) =>  '₹'+data 
        },
        {
            data: 'total_Net_Banking_amount',
            title: "Net Banking Amount",
            render: (data) =>  '₹'+data 
        },
        {
            data: 'total_discount',
            title: "Total Discount",
            render: (data) =>  '₹'+data 
        },
        {
            data: 'total_amount',
            title: "Total Amount", // Added total amount field
            render: (data) =>  '₹'+data 
        },
    ]);
    
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: report,
        actionCallback: (data) => {
            switch (data.method) {
                case "Print":
                    // HandleSendTicket(data?.id);
                    break;
                case "edit":
                    console.log("hello");
                    break;
                case "disable":
                    // DeleteBooking(data?.id);
                    break;
                default:
                    break;
            }
        },
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });
    return (
        <Row>
            <Col lg="12" md="12">
                <Card className="card-block">
                    <Card.Header>
                        <h4 className="">
                            Agents Report
                        </h4>
                    </Card.Header>
                    <Card.Body className='px-0'>
                        <div className="table-responsive">
                            <table
                                id="datatable-ecom"
                                ref={listtableRef}
                                className="data-tables table custom-table movie_table"
                            ></table>
                        </div>
                    </Card.Body>
                    <div className="card-footer text-end card-stretch card-height">
                        <Link to="#javascript:void(0);">
                            <span className="me-2">View all Settlements</span>{" "}
                            <svg
                                width="20"
                                className="icon-20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.5 5L15.5 12L8.5 19"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </svg>
                        </Link>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default AgentReports
