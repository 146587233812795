import React, { useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import axios from 'axios';
import { useMyContext } from '../../../../../Context/MyContextProvider';

const Easebuzz = () => {
    const { api, successAlert, UserData, ErrorAlert,authToken } = useMyContext();
    const [easebuzzKey, setEasebuzzKey] = useState('');
    const [easebuzzSalt, setEasebuzzSalt] = useState('');
    const [status, setStatus] = useState('');
  
    const handleSubmit = async () => {
      try {
        await axios.post(`${api}store-easebuzz`, {
          user_id: UserData?.id,
          easebuzz_key: easebuzzKey,
          easebuzz_salt: easebuzzSalt,
          status,
        }, {
          headers: {
            'Authorization': 'Bearer ' + authToken,
          }
        });
        successAlert('Success', 'Easebuzz credentials stored successfully!');
      } catch (error) {
        ErrorAlert('Error', 'Failed to store Easebuzz credentials.');
      }
    };

  return (
    <Form>
      <Row>
        <Col lg="6">
          <Form.Group className="mb-3 form-group">
            <Form.Label className="custom-file-input">Easebuzz Merchant Key</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={easebuzzKey}
              onChange={(e) => setEasebuzzKey(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Group className="mb-3 form-group">
            <Form.Label className="custom-file-input">Easebuzz Salt</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={easebuzzSalt}
              onChange={(e) => setEasebuzzSalt(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Label className="custom-file-input">Status</Form.Label>
          <Row>
            <Col lg="6" className='d-flex gap-3'>
              <div className="form-radio form-check">
                <Form.Check.Input
                  type="radio"
                  id="enableRadio"
                  name="payment"
                  className="me-2"
                  value="Enable"
                  checked={status === 'Enable'}
                  onChange={(e) => setStatus(e.target.value)}
                />
                <Form.Label
                  className="custom-control-label"
                  htmlFor="enableRadio"
                >
                  Enable
                </Form.Label>
              </div>
              <div className="form-radio form-check">
                <Form.Check.Input
                  type="radio"
                  id="disableRadio"
                  name="payment"
                  className="me-2"
                  value="Disable"
                  checked={status === 'Disable'}
                  onChange={(e) => setStatus(e.target.value)}
                />
                <Form.Label
                  className="custom-control-label"
                  htmlFor="disableRadio"
                >
                  Disable
                </Form.Label>
              </div>
            </Col>
          </Row>
        </Col>
        <div className='d-flex justify-content-end'>
          <Button type="button" onClick={handleSubmit}>Submit</Button>
        </div>
      </Row>
    </Form>
  );
};

export default Easebuzz;
