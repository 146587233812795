import React, { useEffect, useRef } from 'react';
import { fabric } from 'fabric-pure-browser';
import QRCode from "qrcode.react";
import ReactDOMServer from 'react-dom/server';
import { useMyContext } from '../../../../../Context/MyContextProvider';

const TicketCanvas = ({ ticketName, ticketBG }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasRef.current, {
      width: 1100,
      height: 300,
    });

    // Set background image
    fabric.Image.fromURL(ticketBG, (img) => {
      img.set({
        selectable: false,
        evented: false,
        width: canvas.width,
        height: canvas.height,
      });
      canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
    });

    // Add title text to canvas
    const titleText = new fabric.Text(ticketName, {
      left: 100,
      top: 50,
      fontSize: 24,
      fill: 'white',
      selectable: false,
    });

    // Add elements to the canvas
    canvas.add(titleText);
    canvas.renderAll();

    return () => {
      canvas.dispose(); // Cleanup on unmount
    };
  }, [ticketBG, ticketName]);

  return (
    <div>
      <canvas ref={canvasRef} style={{ border: '1px solid black' }} />
    </div>
  );
};

export default TicketCanvas;