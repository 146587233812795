import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
// import reportWebVitals from './reportWebVitals';

//router
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Index from "./views/index";
import { LandingModulesRouter } from "./views/modules/landing-pages/router/landing-modules-router";

//store

import { Provider } from "react-redux";
//reducer
import { IndexRouters } from "./router";
import DefaultLayout from "./views/modules/landing-pages/layouts/default-layout";
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import ProtectedRoutes from "./router/ProtectedRouts/ProtectedRoutes";
import Home from "./views/modules/Event/Home/Home";
const router = createBrowserRouter(
  [
    // {
    //   path: "dashboard",
    //   element: (
    //     <ProtectedRoutes>
    //       <Index />
    //     </ProtectedRoutes>
    //   ),
    // },
    {
      path: "/",
      element: <DefaultLayout header2="true"/>,
      children: [
        {
          path: "",
          element: <Home />,
        }
      ]
    },
    ...IndexRouters,
    ...LandingModulesRouter,
  ],
  { basename: process.env.PUBLIC_URL }
);
ReactDOM.createRoot(document.getElementById("root")).render(
  <>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App>
          <RouterProvider router={router} />
        </App>
      </PersistGate>
    </Provider>
  </>
);

