import React, { memo, Fragment } from "react";

//React-bootstrap
import { Offcanvas, Navbar, Container, Nav, Button, Image } from "react-bootstrap";

//Router
import { Link, useLocation } from "react-router-dom";

const HorizontalNav = memo(() => {
  //location
  let location = useLocation();
  return (
    <Fragment>
      <Navbar
        expand="xl"
        id="navbar_main"
        className="mobile-offcanvas  hover-nav horizontal-nav mx-md-auto "
      >
        <Container fluid>
          <Offcanvas.Header className="px-0" closeButton>
            <Navbar.Brand className="ms-0">
            <Image src={'https://getyourticket.in/wp-content/uploads/2024/07/gyt-300x133.png'} alt="logo" loading="lazy" width={120}/>
               {/* <h4 className="logo-title">Get Your Ticket</h4> */}
            </Navbar.Brand>
            <Button className="close float-end"></Button>
          </Offcanvas.Header>
          <ul className={`${location.pathname === '/boxed' || location.pathname === '/boxedFancy' || location.pathname === '/horizontal' ? 'iq-nav-menu' : 'navbar-nav'} list-unstyled`}>
            <Nav.Item as="li">
              <Link
                className={`${location.pathname === "/horizontal" ? "active" : ""
                  } nav-link `}
                to="/horizontal"
              >
                {" "}
                Horizontal{" "}
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                className={`${location.pathname === "/dual-horizontal" ? "active" : ""
                  } nav-link `}
                to="/dual-horizontal"
              >
                {" "}
                Dual Horizontal{" "}
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                className={`${location.pathname === "/dual-compact" ? "active" : ""
                  } nav-link `}
                to="/dual-compact"
              >
                <span className="item-name">Dual Compact</span>
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                className={`${location.pathname === "/boxed" ? "active" : ""
                  } nav-link `}
                to="/boxed"
              >
                {" "}
                Boxed Horizontal{" "}
              </Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Link
                className={`${location.pathname === "/boxedFancy" ? "active" : ""
                  } nav-link `}
                to="/boxedFancy"
              >
                {" "}
                Boxed Fancy
              </Link>
            </Nav.Item>
          </ul>
        </Container>
      </Navbar>
    </Fragment>
  );
});

HorizontalNav.displayName = "HorizontalNav";
export default HorizontalNav;
