import React, { useState, memo, Fragment, useEffect, useRef } from "react";

// Router
import { Link } from "react-router-dom";

// React-bootstrap
import { Button, Row, Col, Card, Modal, Form, Accordion } from "react-bootstrap";

import axios from "axios";
import { useMyContext } from "../../../../Context/MyContextProvider";
import PosEvents from "../POS/PosEvents";
import QRGenerator from "../Events/Tickets/QRGenerator";
import { createRoot } from "react-dom/client";
import loader from '../../../../assets/event/stock/loder.gif';
import JSZip from "jszip";
import { saveAs } from "file-saver";
import Swal from "sweetalert2";

const ComplimentaryBookings = memo(() => {
    const { api, UserData, isMobile, formatDateTime, ErrorAlert, authToken, successAlert } = useMyContext();
    const [event, setEvent] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [number, setNumber] = useState('');
    const [selectedTicketID, setSelectedTicketID] = useState(null);
    const [resetCounterTrigger, setRsetCounterTrigger] = useState(0)

    const [bookings, setBookings] = useState([])
    const getEventData = async (id) => {
        await axios.get(`${api}event-detail/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    setEvent(res.data.events)
                    setTickets(res.data.events?.tickets)
                }
            }).catch((err) =>
                console.log(err)
            )
    }

    //model states
    const [showPrintModel, setShowPrintModel] = useState(false);

    const [activeKey, setActiveKey] = useState('0');

    useEffect(() => {
        const accordionButton = document.querySelector('.accordion-button');
        if (accordionButton) {
            accordionButton.style.backgroundColor = 'transparent';
        }
    }, []);

    const handleButtonClick = (id) => {
        getEventData(id)
        setActiveKey(null);
    };
    const closePrintModel = () => {
        setShowPrintModel(false)
        setRsetCounterTrigger(resetCounterTrigger + 1)
    }
    const [loading, setLoading] = useState();
    const handleSubmit = async () => {
        const loadingAlert = showLoading();
        setLoading(true)
        let ticketData = tickets?.find((item) => parseInt(item?.id) === parseInt(selectedTicketID))
        if (ticketData) {
            const requestData = {
                user_id: UserData?.id,
                number: UserData?.number,
                email: UserData?.email,
                name: UserData?.name,
                payment_method: 'offline',
                quantity: number,
                ticket_id: ticketData?.id
            };
            try {
                const res = await axios.post(`${api}complimentary-booking/${event?.id}`, requestData, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
                if (res.data.status) {
                    setLoading(false)
                    let data = res.data?.bookings
                    let qrCodeIds = data?.map((data) => data?.token);
                    // setBookingIds(qrCodeIds)
                    setBookings(qrCodeIds)
                    successAlert('Booking Success', 'Complimentary Booking Created Successfully')
                }
            } catch (err) {
                ErrorAlert('something went wrong');
            } finally {
                setLoading(false);
                loadingAlert.close(); // Close the loading alert
                Swal.fire({
                    title: 'Bookings Success!',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });
            }
        } else {
            ErrorAlert('Please Select Event/Ticket First')
        }

    }
    const getTicketData = async (id) => {
        await axios.get(`${api}ticket-info/${id}`, {
            headers: {
              'Authorization': 'Bearer ' + authToken,
            }
          })
            .then((res) => {
                //  
                if (res.data.status) {
                    
                }
            }).catch((err) => { })
    }
    
    //qr
    const handleZip = () => {
        showLoading()
        generateZip()
    }
    // const generateZip = async () => {
    //     if (bookings?.length > 0) {
    //         setLoading(true);
    //         try {
    //             const zip = new JSZip();
    //             const qrCodePromises = bookings.map((data,index) =>
    //                 new Promise((resolve) => {
    //                     const qrElement = document.createElement("div");
    //                     qrElement.style.display = "none";
    //                     document.body.appendChild(qrElement);

    //                     const qrGenerator = <QRGenerator value={data} documentId={index} />;
    //                     const root = createRoot(qrElement);
    //                     root.render(qrGenerator);

    //                     setTimeout(() => {
    //                         const canvas = qrElement.querySelector("canvas");
    //                         if (canvas) {
    //                             canvas.toBlob((blob) => {
    //                                 zip.file(`qrcode_${data}.png`, blob);
    //                                 resolve();
    //                             });
    //                         }
    //                         document.body.removeChild(qrElement);
    //                     }, 1000); // Delay to ensure QR code is rendered
    //                 })
    //             );
    //             await Promise.all(qrCodePromises);
    //             zip.generateAsync({ type: "blob" }).then((content) => {
    //                 saveAs(content, "qr_codes.zip");
    //                 setLoading(false);
    //                 setBookings([])
    //             });
    //         } catch (error) {
    //             console.error("Error generating ZIP:", error);
    //         } finally {
    //             setLoading(false);
    //             const loadingAlert = showLoading();
    //             loadingAlert.close();
    //             Swal.fire({
    //                 title: 'Finished!',
    //                 icon: 'success',
    //                 timer: 2000,
    //                 showConfirmButton: false
    //             });
    //             setNumber('')
    //         }
    //     }
    // };

    const generateZip = async () => {
        const loadingAlert = showLoading();
        if (bookings?.length > 0) {
            setLoading(true);
            try {
                const zip = new JSZip();
                const total = bookings?.length;
                let completed = 0;
                const qrCodePromises = bookings.map((data, index) =>
                    new Promise((resolve) => {
                        const qrElement = document.createElement("div");
                        qrElement.style.display = "none";
                        document.body.appendChild(qrElement);

                        const qrGenerator = <QRGenerator value={data} documentId={index} />;
                        const qrRoot = createRoot(qrElement);
                        qrRoot.render(qrGenerator);

                        setTimeout(() => {
                            const canvas = qrElement.querySelector("canvas");
                            if (canvas) {
                                canvas.toBlob((blob) => {
                                    zip.file(`qrcode_${data}.png`, blob);
                                    completed++;
                                    const progress = Math.round((completed / total) * 100);
                                    loadingAlert.update({
                                        html: `
                                            <div style="text-align: center;">
                                            <img src=${loader} style="width: 10rem; display: block; margin: 0 auto;"/>
                                                <div>Progress: <span id="progress-text">${progress}%</span></div>
                                                <div class="mt-4" style="width: 100%; border: 1px solid  #dddddd4f; border-radius: 10px;">
                                                    <div class="progress-bar bg-primary progress-bar-striped" id="progress-bar" style="width: ${progress}%; height: 10px; text-align: center; color: white; line-height: 20px; border-radius: 4px;"></div>
                                                </div>
                                            </div>
                                        `,
                                        text: `Processing... ${progress}%`
                                    });

                                    resolve();
                                });
                            }
                            document.body.removeChild(qrElement);
                        }, 1000); // Delay to ensure QR code is rendered
                    })
                );
                await Promise.all(qrCodePromises);
                zip.generateAsync({ type: "blob" }).then((content) => {
                    saveAs(content, "qr_codes.zip");
                    setLoading(false);
                });
            } catch (error) {
                console.error("Error generating ZIP:", error);
            } finally {
                setLoading(false);
                loadingAlert.close(); // Close the loading alert
                Swal.fire({
                    title: 'Finished!',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false
                });

            }
        }
    };
    const showLoading = (progress = 0) => {
        return Swal.fire({
            title: "Processing",
            text: progress === 0 ? "Processing will start soon. Please wait..." : "Processing...",
            html: `
                <div style="text-align: center;">
                    <img src=${loader} style="width: 10rem; display: block; margin: 0 auto;"/>
                    <div class="spinner-border text-primary mt-4" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            `,
            allowEscapeKey: false,
            allowOutsideClick: false,
            showConfirmButton: false,
            customClass: {
                htmlContainer: 'swal2-html-container-cutom'
            },

        });
    };
    const handleChange = (e) => {
        const value = Math?.min(1000, e.target.value);
        setNumber(value);
    };
    const HandleTicket = (id) =>{
        setSelectedTicketID(id)
        getTicketData(id)
    }
    return (
        <Fragment>
            {/* print model  */}
            <Modal show={showPrintModel} onHide={() => closePrintModel()} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closePrintModel()}>
                        Close
                    </Button>
                    <Button variant="primary">
                        Print Invoice
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* print model end */}
                <Row>
                    <Col lg="12">
                        <Card>
                            <Card.Body className="py-0">
                                <Accordion flush className="p-0" activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
                                    <Accordion.Item eventKey="0" className="bg-transparent">
                                        <Accordion.Header>Events</Accordion.Header>
                                        <Accordion.Body className="bg-transparent p-0 pt-3">
                                            <Row>
                                                <Col lg="12">
                                                    <Form>
                                                        <Row>
                                                            <Col lg="12">
                                                                <Form.Group className="mb-3 form-group input-group search-input w-100">
                                                                    <input
                                                                        type="search"
                                                                        className="form-control"
                                                                        placeholder="Search Your Event..."
                                                                    />
                                                                    <span className="input-group-text">
                                                                        <svg
                                                                            width="18"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <circle
                                                                                cx="11.7669"
                                                                                cy="11.7666"
                                                                                r="8.98856"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></circle>
                                                                            <path
                                                                                d="M18.0186 18.4851L21.5426 22"
                                                                                stroke="currentColor"
                                                                                strokeWidth="1.5"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            ></path>
                                                                        </svg>
                                                                    </span>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                </Col>
                                                <Col lg="12">
                                                    <PosEvents handleButtonClick={handleButtonClick} />
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="8">
                        <div className="">
                            <Row>
                                <Col>
                                    <Card className="card-block card-stretch card-height">
                                        <Card.Header className="h5">Complimentary Bookings</Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <Form.Group className="col-md-4 form-group">
                                                    <Form.Label>Select Category:</Form.Label>
                                                    <Form.Select
                                                        required
                                                        value={selectedTicketID}
                                                        onChange={(e) => HandleTicket(e.target.value)}
                                                    >
                                                        <option value=''>Select</option>
                                                        {
                                                            tickets?.map((item, index) => (
                                                                <option value={item?.id} key={index}>{item?.name}</option>
                                                            ))
                                                        }
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">Please Select Role</Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className="col-md-4 form-group">
                                                    <Form.Label htmlFor="fname">Total Ticket:</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        id="fname"
                                                        placeholder=""
                                                        required
                                                        value={number}
                                                        onChange={handleChange}
                                                    />
                                                    <div className="text-secondary">❕ You can generate ticket max 1000.</div>
                                                </Form.Group>
                                                <Form.Group className="col-md-4 form-group">
                                                    <Form.Label>&nbsp;</Form.Label>
                                                    {
                                                        bookings?.length > 0 ?
                                                            <div className="btn d-flex justify-content-end">
                                                                <Button variant="btn btn-secondary"
                                                                    disabled={loading}
                                                                    onClick={() => handleZip()}>
                                                                    Download QR Code PDF
                                                                </Button>
                                                                {/* <PDFDownloadLink
                                                                    document={<QRCodeDocument qrCodes={qrCodes} />}
                                                                    fileName="bookings.pdf"
                                                                >
                                                                    {({ loading }) => (loading ? "Loading..." : "Download PDF")}
                                                                </PDFDownloadLink> */}
                                                            </div>
                                                            :
                                                            <div className="btn d-flex justify-content-end">
                                                                <Button onClick={handleSubmit} variant="btn btn-primary">
                                                                    Submit Booking
                                                                </Button>
                                                            </div>
                                                    }
                                                </Form.Group>
                                            </Row>
                                            {/* <small>Available to pay out.</small> */}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Col>

                </Row>
        </Fragment >
    );
});

ComplimentaryBookings.displayName = "ComplimentaryBookings";
export default ComplimentaryBookings;
