import React from "react";

// default layout
import Default from "../layouts/dashboard/default";

// Dashboard

import AlternateDashboard from "../views/dashboard/alternate-dashboard";


import Users from "../views/modules/Event/User/Users";
import NewChekout from "../views/modules/Event/Events/LandingEvents/newCheckout";
import Bookings from "../views/modules/Event/Events/Bookings/Bookings";
import ManageUser from "../views/modules/Event/User/ManageUser";
import PosBooking from "../views/modules/Event/POS/PosBooking2";
import AgentBooking from "../views/modules/Event/Agent/AgentBooking";
import Agent from "../views/modules/Event/Agent/Agent";
import Scanner from "../views/modules/Event/Scanner/Scanner";
import Camera from "../views/modules/Event/Scanner/Camera";
import POS from "../views/modules/Event/POS/pos";
import MailSetting from "../views/modules/Event/AdminSetting/MailSetting";
import PaymentGateway from "../views/modules/Event/AdminSetting/PaymentGateway/PaymentGateway";
import SmsSetting from "../views/modules/Event/AdminSetting/SmsSetting";
import AdminSetting from "../views/modules/Event/AdminSetting/AdminSetting";
import Events from "../views/modules/Event/Events/DashboardEvents/Events";
import MakeEvent from "../views/modules/Event/Events/DashboardEvents/MakeEvent";
import AddEvent from "../views/modules/Event/Events/DashboardEvents/AddEvents";
import Tax from "../views/modules/Event/Taxes/Tax";
import NewUser from "../views/modules/Event/User/NewUser";
import EditEvent from "../views/modules/Event/Events/DashboardEvents/EditEvent";
import Roles from "../views/modules/Event/Events/RolePermission/Roles";
import RolePermission from "../views/modules/Event/Events/RolePermission/RolePermission";
import AdminBookings from "../views/modules/Event/Events/Bookings/AdminBookings";
import ComplimentaryBookings from "../views/modules/Event/Complimentary/ComplimentaryBookings";
import CbList from "../views/modules/Event/Complimentary/CbList";
import Index from "../views/modules/Event/Dashboard";
import EventReports from "../views/modules/Event/Reports/EventReports";
import HomeSetting from "../views/modules/Event/AdminSetting/HomeSetting";
import AgentReports from "../views/modules/Event/Reports/AgentReports";
import PosReports from "../views/modules/Event/Reports/PosReports";
import OnlineReport from "../views/modules/Event/Reports/OnlineReport";
export const DefaultRouter = [
  {
    path: "/",
    element: <Default />,
    // element: <Home />,
    children: [
      //custom 
      {
        path: "events/:id/process",
        element: <NewChekout />,
        name: 'process',
        active: 'process'
      },
      {
        path: "dashboard/",
        name: 'home',
        active: 'home',
        children: [
          {
            path: "",
            element: <Index />,
            name: 'dashboard',
            active: 'home'
          },
          {
            path: "alternate-dashboard",
            element: <AlternateDashboard />,
            name: 'alternate dashboard',
            active: 'home'
          },
          {
            path: "tax",
            element: <Tax />,
            name: 'alternate dashboard',
            active: 'home'
          },
          {
            path: "bookings-admin",
            element: <AdminBookings />,
            name: 'bookings-admin',
            active: 'home'
          },
          {
            path: "complimentary-bookings",
            element: <CbList/>,
            name: 'bookings-admin',
            active: 'home'
          },
          {
            path: "complimentary-bookings/new",
            element: <ComplimentaryBookings/>,
            name: 'bookings-admin',
            active: 'home'
          },
          {
            path: "events/",
            element: <Events />,
            name: 'alternate dashboard',
            active: 'events',
          },
          {
            path: "events/",
            active: 'events',
            children: [
              {
                path: "new",
                element: <MakeEvent />,
                name: 'new events',
                active: 'events'
              },
              {
                path: "edit/:id",
                element: <EditEvent />,
                name: 'edit events',
                active: 'events'
              },
              {
                path: "new/detail-info",
                element: <AddEvent />,
                name: 'new events',
                active: 'events'
              },
            ]
          },
          {
            path: 'bookings',
            element: <Bookings />,
            name: 'bookings'
          },
          {
            path: 'pos-bookings',
            element: <PosBooking />,
            name: 'Pos Bookings'
          },
          {
            path: 'agent-bookings',
            element: <AgentBooking />,
            name: 'agent-bookings'
          },
          {
            path: 'agent-bookings/new',
            element: <Agent />,
            name: 'agent-bookings'
          },
          {
            path: 'scan/',
            // element: <Scanner />,
            name: 'Scan',
            children: [
              {
                path: "scanner",
                element: <Scanner />,
                name: 'Scanner',
                subActive: 'scanner'
              },
              {
                path: "camera",
                element: <Camera />,
                name: 'Camera',
                subActive: 'camera'
              }
            ]
          },
          {
            path: 'pos',
            element: <POS />,
            name: 'POS'
          },
          {
            path: 'roles/',
            name: 'roles',
            children: [
              {
                path: "",
                element: <Roles />,
                name: 'roles',
                active: 'roles'
              },
              {
                path: "assign-permission/:id",
                element: <RolePermission />,
                name: 'Permission',
                active: 'Permission'
              },
            ]
          },
          {
            path: "users",
            element: <Users />,
            name: 'User List',
            active: 'pages',
            subActive: 'User'
          },
          {
            path: "users/manage/:id",
            element: <ManageUser />,
            name: 'User List',
            active: 'pages',
            subActive: 'User'
          },
          {
            path: "users/new",
            // element: <NewUserWizard />,
            element: <NewUser />,
            name: 'User List',
            active: 'pages',
            subActive: 'User'
          },
          {
            path: "settings/",
            active: 'settings',
            // element: <Setting />,
            children: [
              {
                path: "admin",
                element: <AdminSetting />,
                name: 'Admin',
                subActive: 'Admin'
              },
              {
                path: "home-setting",
                element: <HomeSetting />,
                name: 'home-setting',
                subActive: 'home-setting'
              },
              {
                path: "mail",
                element: <MailSetting />,
                name: 'Mail',
                subActive: 'Mail'
              },
              {
                path: "payment-gateway",
                element: <PaymentGateway />,
                name: 'Payment',
                subActive: 'Payment'
              },
              {
                path: "sms-gateway",
                element: <SmsSetting />,
                name: 'SMS',
                subActive: 'SMS'
              },
              {
                path: "otp",
                element: <AdminSetting />,
                name: 'OTP',
                subActive: 'OTP'
              },
              {
                path: "social-media",
                element: <AdminSetting />,
                name: 'Social Media',
                subActive: 'social-media'
              },
            ]

          },
          {
            path: "reports/",
            active: 'reports',
            // element: <Setting />,
            children: [
              {
                path: "event-report",
                element: <EventReports />,
                name: 'Admin',
                subActive: 'event-report'
              },
              {
                path: "agent-report",
                element: <AgentReports />,
                name: 'Admin',
                subActive: 'agent-report'
              },
              {
                path: "pos-report",
                element: <PosReports />,
                name: 'Admin',
                subActive: 'pos-report'
              },
              {
                path: "online-report",
                element: <OnlineReport />,
                name: 'Admin',
                subActive: 'online-report'
              },
            ]

          },
        ]
      },
    ],
  },
];
