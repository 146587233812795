import React, { useEffect, useState } from "react";

// React-bootstrap
import {
    Row,
    Col,
    Image,
    Dropdown,
    Button,
    Table,
    Form,
    Tab,
    Nav,
    Card,
} from "react-bootstrap";

// Router
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
// Img
import img8 from "../../../../assets/modules/e-commerce/images/user/06.png";
import axios from "axios";
import { useMyContext } from "../../../../Context/MyContextProvider";
import AgentCredit from "./AgentCredit";
import BookingList from "../Events/Bookings/BookingList";
const ManageUser = () => {
    const { api, authToken, successAlert, userRole, UserData, UserList } = useMyContext();
    const { id } = useParams()
    const [bookings, setBookings] = useState([])
    const [roles, setRoles] = useState([])
    const [users, setUsers] = useState([])
    const [isUser, setIsUser] = useState(false);



    //user states
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [number, setNumber] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [altNumber, setAltNumber] = useState('');
    const [pincode, setPincode] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankNumber, setBankNumber] = useState('');
    const [bankIfsc, setBankIfsc] = useState('');
    const [bankBranch, setBankBranch] = useState('');
    const [bankMicr, setBankMicr] = useState('');
    const [validated, setValidated] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState('');
    const [roleId, setRoleId] = useState('');
    const [reportingUser, setReportingUser] = useState('');
    const [userType, setUserType] = useState('');
    const [status, setStatus] = useState(false);
    const [roleName, setRoleName] = useState(false);
    const [disableOrg, setDisableOrg] = useState(false);
    const [disable, setDisable] = useState(false);

    const getBookings = async () => {
        await axios.get(`${api}user-bookings/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setBookings(res.data.bookings)
            }
        }).catch((err) =>
            console.log(err)
        )
    }
    const UserDetail = async () => {
        await axios.get(`${api}edit-user/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                const data = res.data?.user[0];
                setName(data.name);
                setEmail(data.email);
                setPassword(data.password);
                setNumber(data.phone_number);
                setOrganisation(data.organisation);
                setAltNumber(data.alt_number);
                setPincode(data.pincode);
                setState(data.state);
                setCity(data.city);
                setBankName(data.bank_name);
                setBankNumber(data.bank_number);
                setBankIfsc(data.bank_ifsc);
                setBankBranch(data.bank_branch);
                setStatus(data.status === 1 ? 'Active' : 'Deative');
                setBankMicr(data.bank_micr);
                setReportingUser(UserList.find((item) => item?.value === data.reporting_user));
                setRoleName(data?.reporting_user_id)
                setRoleId(data?.role?.id)
                setRoles(res.data?.roles)
                setUsers(res.data?.allUser)
                fetchUserRole(data.reporting_user)
            }
        }).catch((err) =>
            console.log(err)
        )
    }
    const WalletData = async () => {
        await axios.get(`${api}chek-user/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
            }
        }).catch((err) =>
            console.log(err)
        )
    }
    useEffect(() => {
        if (userRole === 'Organizer') {
            setReportingUser({ key: UserData?.id, label: UserData?.id })
            setDisableOrg(true)
            setOrganisation(UserData?.organisation)
        }
        getBookings()
        UserDetail()
        WalletData()

    }, []);
    const handleSubmit = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            setValidated(true);
            const userData = {
                name,
                email,
                number,
                password,
                organisation,
                alt_number: altNumber,
                pincode,
                state,
                city,
                bank_name: bankName,
                reporting_user: reportingUser?.value,
                bank_number: bankNumber,
                role_id: roleId,
                bank_ifsc: bankIfsc,
                bank_branch: bankBranch,
                bank_micr: bankMicr,
                status: status === 'Active' ? 1 : 0,
            };
            try {
                const response = await axios.post(`${api}update-user/${id}`, userData, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
                successAlert('User Updated', response.data.message);
            } catch (error) {
                console.error('There was an error creating the user!', error);
            }
        }
    };

    const fetchUserRole = async (reporting_id) => {
        await axios.get(`${api}edit-user/${reporting_id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                const data = res.data?.user[0];
                if (data?.role?.name === 'Organizer') {
                    setOrganisation(data?.organisation)
                    setDisable(true)
                }else{
                    setOrganisation('')
                    setDisable(false)

                }
            }
        }).catch((err) =>
            console.log(err)
        )
    }

    useEffect(() => {
        if (roles?.length > 0 && roleId) {
            const role = roles.find((item) => item.id === Number(roleId));
            setRoleName(role?.name)
        }
    }, [roleId, roles]);

    useEffect(() => {
        if (reportingUser) {
            fetchUserRole(reportingUser?.value)
        }
    }, [reportingUser]);
    return (
        <>
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Header>
                            <h4 className="card-title">Manage User</h4>
                        </Card.Header>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                <Nav
                                    className="nav nav-tabs nav-iconly gap-5 mb-5"
                                    id="myTab"
                                    role="tablist"
                                >
                                    <Nav.Link
                                        as="button"
                                        eventKey="first"
                                        className="d-flex flex-column align-items-center w-100 "
                                        id="profile-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#profile"
                                        type="button"
                                        role="tab"
                                        aria-controls="profile"
                                        aria-selected="false"
                                    >
                                        <svg
                                            className="icon-40"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="40"
                                            viewBox="0 0 40 40"
                                            fill="currentColor"
                                        >
                                            <path
                                                d="M19.9947 25.291C12.8063 25.291 6.66632 26.4243 6.66632 30.9577C6.66632 35.4927 12.768 36.666 19.9947 36.666C27.183 36.666 33.323 35.5343 33.323 30.9993C33.323 26.4643 27.223 25.291 19.9947 25.291"
                                                fill="currentColor"
                                            />
                                            <path
                                                opacity="0.4"
                                                d="M19.9947 20.9728C24.8913 20.9728 28.8147 17.0478 28.8147 12.1528C28.8147 7.25782 24.8913 3.33282 19.9947 3.33282C15.0997 3.33282 11.1747 7.25782 11.1747 12.1528C11.1747 17.0478 15.0997 20.9728 19.9947 20.9728"
                                                fill="currentColor"
                                            />
                                        </svg>
                                        Profile
                                    </Nav.Link>
                                    <Nav.Link
                                        as="button"
                                        eventKey="second"
                                        className="d-flex flex-column align-items-center w-100"
                                        id="order-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#order"
                                        type="button"
                                        role="tab"
                                        aria-controls="order"
                                        aria-selected="false"
                                    >
                                        <svg
                                            className="icon-40"
                                            width="40"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                opacity="0.4"
                                                d="M16.6203 22H7.3797C4.68923 22 2.5 19.8311 2.5 17.1646V11.8354C2.5 9.16894 4.68923 7 7.3797 7H16.6203C19.3108 7 21.5 9.16894 21.5 11.8354V17.1646C21.5 19.8311 19.3108 22 16.6203 22Z"
                                                fill="currentColor"
                                            ></path>
                                            <path
                                                d="M15.7551 10C15.344 10 15.0103 9.67634 15.0103 9.27754V6.35689C15.0103 4.75111 13.6635 3.44491 12.0089 3.44491C11.2472 3.44491 10.4477 3.7416 9.87861 4.28778C9.30854 4.83588 8.99272 5.56508 8.98974 6.34341V9.27754C8.98974 9.67634 8.65604 10 8.24487 10C7.8337 10 7.5 9.67634 7.5 9.27754V6.35689C7.50497 5.17303 7.97771 4.08067 8.82984 3.26285C9.68098 2.44311 10.7814 2.03179 12.0119 2C14.4849 2 16.5 3.95449 16.5 6.35689V9.27754C16.5 9.67634 16.1663 10 15.7551 10Z"
                                                fill="currentColor"
                                            ></path>
                                        </svg>
                                        Bookings
                                    </Nav.Link>
                                    {roleName === 'Agent' &&
                                        <Nav.Link
                                            as="button"
                                            eventKey="third"
                                            className="d-flex flex-column align-items-center w-100"
                                            id="card-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#card"
                                            type="button"
                                            role="tab"
                                            aria-controls="card"
                                            aria-selected="true"
                                        >
                                            <svg
                                                className="icon-40"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="40"
                                                viewBox="0 0 40 40"
                                                fill="currentColor"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M36.6607 13.9585H29.6029C26.3185 13.9643 23.6578 16.5586 23.6518 19.7609C23.6474 22.9705 26.3111 25.5763 29.6029 25.5807H36.6666V26.0906C36.6666 31.6893 33.2726 35 27.5289 35H12.4726C6.72739 35 3.33331 31.6893 3.33331 26.0906V13.8964C3.33331 8.2977 6.72739 5 12.4726 5H27.5229C33.2666 5 36.6607 8.2977 36.6607 13.8964V13.9585ZM11.2326 13.9455H20.6326H20.6385H20.6504C21.3541 13.9427 21.9229 13.3836 21.92 12.6961C21.917 12.01 21.3422 11.4553 20.6385 11.4582H11.2326C10.5333 11.4611 9.96591 12.0143 9.96294 12.6975C9.95998 13.3836 10.5289 13.9427 11.2326 13.9455Z"
                                                    fill="currentColor"
                                                />
                                                <path
                                                    opacity="0.4"
                                                    d="M26.729 20.4944C27.0775 22.0798 28.4674 23.1951 30.0543 23.1661H35.4708C36.1311 23.1661 36.6666 22.6193 36.6666 21.9434V17.7242C36.6652 17.0497 36.1311 16.5015 35.4708 16.5H29.9268C28.1217 16.5058 26.6638 18.0041 26.6666 19.8505C26.6666 20.0666 26.6879 20.2827 26.729 20.4944"
                                                    fill="currentColor"
                                                />
                                                <ellipse
                                                    cx="30"
                                                    cy="19.8333"
                                                    rx="1.66667"
                                                    ry="1.66667"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                            Wallet
                                        </Nav.Link>
                                    }
                                </Nav>
                                <Tab.Content>
                                    <Tab.Pane
                                        eventKey="first"
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <Form noValidate validated={validated} className='row g-3 needs-validation'>
                                            <Row>
                                                <Col xl={userRole === 'Organizer' ? '12' : '12'} lg="8">
                                                    <Form>
                                                        <div className="header-title d-flex justify-content-between align-items-center w-100">
                                                            <h4 className="card-title">Update {userType ? userType : 'User'} Information</h4>
                                                            <div className="btn">
                                                                <Button onClick={handleSubmit} variant="btn btn-primary">
                                                                    Save
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <div className="new-user-info">
                                                            <Row>
                                                                <Form.Group className="col-md-4 form-group">
                                                                    <Form.Label htmlFor="fname">Name:</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        id="fname"
                                                                        placeholder="Name"
                                                                        value={name}
                                                                        required
                                                                        onChange={(e) => setName(e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className="col-md-4 form-group">
                                                                    <Form.Label htmlFor="email">Email:</Form.Label>
                                                                    <Form.Control
                                                                        type="email"
                                                                        id="email"
                                                                        required
                                                                        placeholder="Email"
                                                                        autoComplete="new-password"
                                                                        name="new-password-field"
                                                                        value={email}
                                                                        onChange={(e) => setEmail(e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className="col-md-4 form-group">
                                                                    <Form.Label htmlFor="mobno">Mobile Number:</Form.Label>
                                                                    <Form.Control
                                                                        type="number"
                                                                        id="mobno"
                                                                        placeholder="Mobile Number"
                                                                        value={number}
                                                                        required
                                                                        onChange={(e) => setNumber(e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                                <Form.Group className="col-md-4 form-group">
                                                                    <Form.Label htmlFor="lname">Organisation:</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        id="lname"
                                                                        required
                                                                        disabled={disableOrg || disable}
                                                                        placeholder="Organisation"
                                                                        value={organisation}
                                                                        onChange={(e) => setOrganisation(e.target.value)}
                                                                    />
                                                                </Form.Group>
                                                                {!disableOrg &&
                                                                    <Form.Group className="col-md-4 form-group">
                                                                        <Form.Label htmlFor="gstvat">Account Manager :</Form.Label>
                                                                        <Select
                                                                            options={UserList}
                                                                            value={reportingUser}
                                                                            className="js-choice"
                                                                            select="one"
                                                                            onChange={(user) => setReportingUser(user)}
                                                                        />
                                                                    </Form.Group>
                                                                }
                                                                {!disableOrg &&
                                                                    <>
                                                                        <Form.Group className="col-md-4 form-group">
                                                                            <Form.Label>User Role:</Form.Label>
                                                                            <Form.Select
                                                                                required
                                                                                value={roleId}
                                                                                onChange={(e) => setRoleId(e.target.value)}
                                                                            >
                                                                                <option value=''>Select</option>
                                                                                {
                                                                                    roles?.map((item, index) => (
                                                                                        <option value={item?.id} key={index}>{item?.name}</option>
                                                                                    ))
                                                                                }
                                                                            </Form.Select>
                                                                            <Form.Control.Feedback type="invalid">Please Select Role</Form.Control.Feedback>
                                                                        </Form.Group>
                                                                    </>
                                                                }
                                                                {!userType &&
                                                                    <>
                                                                        <hr />
                                                                        <h5 className="mb-3">Banking</h5>
                                                                        <Form.Group className="col-md-3 form-group">
                                                                            <Form.Label htmlFor="add1">Bank Name:</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                id="add1"

                                                                                placeholder="Bank Name"
                                                                                value={bankName}
                                                                                onChange={(e) => setBankName(e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group className="col-md-3 form-group">
                                                                            <Form.Label htmlFor="add2">Bank IFSC Code:</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                id="add2"

                                                                                placeholder="Bank IFSC Code"
                                                                                value={bankIfsc}
                                                                                onChange={(e) => setBankIfsc(e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group className="col-md-3 form-group">
                                                                            <Form.Label htmlFor="cname">Branch Name:</Form.Label>
                                                                            <Form.Control
                                                                                type="text"
                                                                                id="cname"

                                                                                placeholder="Branch Name"
                                                                                value={bankBranch}
                                                                                onChange={(e) => setBankBranch(e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                        <Form.Group className="col-md-3 form-group">
                                                                            <Form.Label htmlFor="cname">Account Number:</Form.Label>
                                                                            <Form.Control
                                                                                type="number"
                                                                                id="cname"

                                                                                placeholder="Account Number"
                                                                                value={bankNumber}
                                                                                onChange={(e) => setBankNumber(e.target.value)}
                                                                            />
                                                                        </Form.Group>
                                                                    </>
                                                                }
                                                                <hr />
                                                                <div className="col-md-12">
                                                                    <div className="row">
                                                                        <div className="col-md-6">
                                                                            <h5 className="mb-3">Address</h5>
                                                                            <div className="row">
                                                                                <Form.Group className="col-md-6 form-group">
                                                                                    <Form.Label htmlFor="city">Town/City:</Form.Label>
                                                                                    <Form.Control
                                                                                        type="text"
                                                                                        id="city"

                                                                                        placeholder="Town/City"
                                                                                        value={city}
                                                                                        onChange={(e) => setCity(e.target.value)}
                                                                                    />
                                                                                </Form.Group>
                                                                                <Form.Group className="col-md-6 form-group">
                                                                                    <Form.Label htmlFor="pno">Pin Code:</Form.Label>
                                                                                    <Form.Control
                                                                                        type="number"
                                                                                        id="pno"
                                                                                        placeholder="Pin Code"
                                                                                        value={pincode}
                                                                                        onChange={(e) => setPincode(e.target.value)}
                                                                                    />
                                                                                </Form.Group>
                                                                            </div>
                                                                        </div>
                                                                        {userType === '' &&
                                                                            <div className="col-md-6">
                                                                                <h5 className="mb-3">Other</h5>
                                                                                <div className="row">
                                                                                    <Form.Group className="col-md-6 form-group">
                                                                                        <Form.Label htmlFor="gstvat">GST / VAT Tax:</Form.Label>
                                                                                        <Form.Control
                                                                                            type="text"
                                                                                            id="gstvat"
                                                                                            placeholder="GST / VAT Tax"
                                                                                            onChange={(e) => (e.target.value)}
                                                                                        />
                                                                                    </Form.Group>
                                                                                    <Form.Group className="col-md-6 form-group">
                                                                                        <Form.Label>User Status:</Form.Label>
                                                                                        <Form.Select
                                                                                            required
                                                                                            value={status}
                                                                                            onChange={(e) => setStatus(e.target.value)}
                                                                                        >
                                                                                            <option value='Active'>Active</option>
                                                                                            <option value='Deative'>Deactive</option>
                                                                                        </Form.Select>
                                                                                        <Form.Control.Feedback type="invalid">Please Select Role</Form.Control.Feedback>
                                                                                    </Form.Group>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </Form>
                                                </Col>
                                            </Row>
                                        </Form>
                                        <Card className="border border-dashed border-2 shadow-none mb-0 rounded border-primary">
                                            <div className="card-header">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h4 className="mb-0">Secure Your Account</h4>
                                                    <Button className="btn btn-primary">Enable</Button>
                                                </div>
                                            </div>
                                            <Card.Body>
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="32"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            className="text-success"
                                                        >
                                                            <path
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                d="M11.9845 21.606C11.9845 21.606 19.6565 19.283 19.6565 12.879C19.6565 6.474 19.9345 5.974 19.3195 5.358C18.7035 4.742 12.9905 2.75 11.9845 2.75C10.9785 2.75 5.26551 4.742 4.65051 5.358C4.03451 5.974 4.31251 6.474 4.31251 12.879C4.31251 19.283 11.9845 21.606 11.9845 21.606Z"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                            <path
                                                                d="M9.38591 11.8746L11.2779 13.7696L15.1759 9.86963"
                                                                stroke="currentColor"
                                                                strokeWidth="1.5"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <p className="ms-3 mb-0">
                                                        Two-factor authentication adds an extra layer of
                                                        security to your account. To log in, in addition
                                                        you'll need to provide a 6 digit code
                                                    </p>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="second"
                                        id="order"
                                        role="tabpanel"
                                        aria-labelledby="order-tab"
                                    >
                                        <BookingList bookings={bookings} />
                                        <div className="col-12 text-center">
                                            <Link to="#" className="btn btn-primary">
                                                Load More
                                            </Link>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane
                                        eventKey="third"
                                        id="card"
                                        role="tabpanel"
                                        aria-labelledby="card-tab"
                                    >
                                        <h4 className="mb-4">Your Payment Options</h4>
                                        <AgentCredit id={id} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default ManageUser
