import React, { memo, Fragment, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Image, Form, Button, Card } from "react-bootstrap";

//router
import { Link, useLocation, useNavigate } from "react-router-dom";

// img
import avatars1 from "../../../../assets/images/avatars/01.png";
import avatars2 from "../../../../assets/images/avatars/avtar_1.png";
import avatars3 from "../../../../assets/images/avatars/avtar_2.png";
import avatars4 from "../../../../assets/images/avatars/avtar_3.png";
import avatars5 from "../../../../assets/images/avatars/avtar_4.png";
import avatars6 from "../../../../assets/images/avatars/avtar_5.png";
import { useMyContext } from "../../../../Context/MyContextProvider";
import axios from "axios";
import Select from "react-select";


const NewUser = memo(() => {

    const { api, successAlert, UserList, userRole, UserData, authToken } = useMyContext();
    const navigate = useNavigate();
    // const query = useQuery();
    const location = useLocation();



    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [number, setNumber] = useState('');
    const [organisation, setOrganisation] = useState('');
    const [altNumber, setAltNumber] = useState('');
    const [pincode, setPincode] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [bankName, setBankName] = useState('');
    const [bankNumber, setBankNumber] = useState('');
    const [bankIfsc, setBankIfsc] = useState('');
    const [bankBranch, setBankBranch] = useState('');
    const [bankMicr, setBankMicr] = useState('');
    const [roles, setRoles] = useState([]);
    const [validated, setValidated] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState('');
    const [roleId, setRoleId] = useState('');
    const [reportingUser, setReportingUser] = useState('');
    const [userType, setUserType] = useState('');
    const [disableOrg, setDisableOrg] = useState(false);
    //role
    const RoleData = async () => {
        try {
            const response = await axios.get(`${api}role-list`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });
            const data = (response.data.role).reverse();
            setRoles(data);
        } catch (error) {
            console.log(error);
        }
    }
    const handleSubmit = async (e) => {
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            setValidated(true);
            const userData = {
                name,
                email,
                number,
                password,
                organisation,
                alt_number: altNumber,
                pincode,
                state,
                city,
                bank_name: bankName,
                reporting_user: reportingUser?.key,
                bank_number: bankNumber,
                role_id: roleId,
                bank_ifsc: bankIfsc,
                bank_branch: bankBranch,
                bank_micr: bankMicr
            };
            try {
                const response = await axios.post(`${api}create-user`, userData, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
                successAlert('User created', response.data.message);
                navigate('/dashboard/users')
            } catch (error) {
                console.error('There was an error creating the user!', error);
            }
        }
    };


    useEffect(() => {
        if (userRole === 'Organizer') {
            setReportingUser({ key: UserData?.id, label: UserData?.id })
            setDisableOrg(true)
            setOrganisation(UserData?.organisation)
        }
        const queryParams = new URLSearchParams(location.search);
        const typeParam = queryParams.get('type');
        setUserType(typeParam?.replace(/-/g, ' '));
        RoleData();

        return () => {
            const urlParams = new URLSearchParams(location.search);
            urlParams.delete('type');
        }
    }, [])

    useEffect(() => {
        if (userType && roles && Array.isArray(roles)) {
            const role = roles.find((item) => item?.name?.includes(userType));
            if (role) {
                setRoleId(role?.id);
            }
        }
    }, [userType, roles]);
    return (
        <Fragment>
            <Form noValidate validated={validated} className='row g-3 needs-validation'>
                <Row>
                    {userRole !== 'Organizer' &&
                        <Col xl="3" lg="4" className="">
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title">
                                        <h4 className="card-title">Add New User</h4>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    {/* <Form>
                            </Form> */}
                                    <Form.Group className="form-group">
                                        <div className="profile-img-edit position-relative">
                                            <Image
                                                className="theme-color-default-img  profile-pic rounded avatar-100"
                                                src={avatars1}
                                                alt="profile-pic"
                                            />
                                            <Image
                                                className="theme-color-purple-img profile-pic rounded avatar-100"
                                                src={avatars2}
                                                alt="profile-pic"
                                            />
                                            <Image
                                                className="theme-color-blue-img profile-pic rounded avatar-100"
                                                src={avatars3}
                                                alt="profile-pic"
                                            />
                                            <Image
                                                className="theme-color-green-img profile-pic rounded avatar-100"
                                                src={avatars5}
                                                alt="profile-pic"
                                            />
                                            <Image
                                                className="theme-color-yellow-img profile-pic rounded avatar-100"
                                                src={avatars6}
                                                alt="profile-pic"
                                            />
                                            <Image
                                                className="theme-color-pink-img profile-pic rounded avatar-100"
                                                src={avatars4}
                                                alt="profile-pic"
                                            />
                                            <div className="upload-icone bg-primary">
                                                <svg
                                                    className="upload-button icon-14"
                                                    width="14"
                                                    height="14"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path
                                                        fill="#ffffff"
                                                        d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z"
                                                    />
                                                </svg>
                                                <Form.Control
                                                    className="file-upload"
                                                    type="file"
                                                    accept="image/*"
                                                />
                                            </div>
                                        </div>
                                        <div className="img-extension mt-3">
                                            <div className="d-inline-block align-items-center">
                                                <span>Only</span> <Link to="#">.jpg</Link>{" "}
                                                <Link to="#">.png</Link> <Link to="#">.jpeg</Link>{" "}
                                                <span>allowed</span>
                                            </div>
                                        </div>
                                    </Form.Group>
                                    <Form.Group className="form-group">
                                        <Form.Label>User Role:</Form.Label>
                                        <Form.Select
                                            required
                                            value={roleId}
                                            onChange={(e) => setRoleId(e.target.value)}
                                        >
                                            <option value=''>Select</option>
                                            {
                                                roles?.map((item, index) => (
                                                    <option value={item?.id} key={index}>{item?.name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">Please Select Role</Form.Control.Feedback>
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    }
                    <Col xl={userRole === 'Organizer' ? '12' : '9'} lg="8">
                        <Form>
                            <Card>
                                <Card.Header className="d-flex justify-content-between">
                                    <div className="header-title d-flex justify-content-between align-items-center w-100">
                                        <h4 className="card-title">New {userType ? userType : 'User'} Information</h4>
                                        <div className="btn">
                                            <Button onClick={handleSubmit} variant="btn btn-primary">
                                                Save
                                            </Button>
                                        </div>
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <div className="new-user-info">
                                        <Row>
                                            <Form.Group className="col-md-3 form-group">
                                                <Form.Label htmlFor="fname">Name:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="fname"
                                                    placeholder="Name"
                                                    value={name}
                                                    required
                                                    onChange={(e) => setName(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-3 form-group">
                                                <Form.Label htmlFor="mobno">Mobile Number:</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    id="mobno"
                                                    placeholder="Mobile Number"
                                                    value={number}
                                                    required
                                                    onChange={(e) => setNumber(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-3 form-group">
                                                <Form.Label htmlFor="lname">Organisation:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    id="lname"
                                                    required
                                                    disabled={disableOrg}
                                                    placeholder="Organisation"
                                                    value={organisation}
                                                    onChange={(e) => setOrganisation(e.target.value)}
                                                />
                                            </Form.Group>
                                            {!disableOrg &&
                                                <Form.Group className="col-md-3 form-group">
                                                    <Form.Label htmlFor="gstvat">Account Manager :</Form.Label>
                                                    <Select
                                                        options={UserList}
                                                        value={reportingUser}
                                                        className="js-choice"
                                                        select="one"
                                                        onChange={(user) => setReportingUser(user)}
                                                    />
                                                </Form.Group>
                                            }
                                            {!userType&&
                                                <>
                                                    <hr />
                                                    <h5 className="mb-3">Banking</h5>
                                                    <Form.Group className="col-md-3 form-group">
                                                        <Form.Label htmlFor="add1">Bank Name:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            id="add1"

                                                            placeholder="Bank Name"
                                                            value={bankName}
                                                            onChange={(e) => setBankName(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="col-md-3 form-group">
                                                        <Form.Label htmlFor="add2">Bank IFSC Code:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            id="add2"

                                                            placeholder="Bank IFSC Code"
                                                            value={bankIfsc}
                                                            onChange={(e) => setBankIfsc(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="col-md-3 form-group">
                                                        <Form.Label htmlFor="cname">Branch Name:</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            id="cname"

                                                            placeholder="Branch Name"
                                                            value={bankBranch}
                                                            onChange={(e) => setBankBranch(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="col-md-3 form-group">
                                                        <Form.Label htmlFor="cname">Account Number:</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            id="cname"

                                                            placeholder="Account Number"
                                                            value={bankNumber}
                                                            onChange={(e) => setBankNumber(e.target.value)}
                                                        />
                                                    </Form.Group>
                                                </>
                                            }
                                            <hr />
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <h5 className="mb-3">Address</h5>
                                                        <div className="row">
                                                            <Form.Group className="col-md-6 form-group">
                                                                <Form.Label htmlFor="city">Town/City:</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    id="city"

                                                                    placeholder="Town/City"
                                                                    value={city}
                                                                    onChange={(e) => setCity(e.target.value)}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="col-md-6 form-group">
                                                                <Form.Label htmlFor="pno">Pin Code:</Form.Label>
                                                                <Form.Control
                                                                    type="number"
                                                                    id="pno"

                                                                    placeholder="Pin Code"
                                                                    value={pincode}
                                                                    onChange={(e) => setPincode(e.target.value)}
                                                                />
                                                            </Form.Group>
                                                        </div>
                                                    </div>
                                                    {userType === '' &&
                                                        <div className="col-md-6">
                                                            <h5 className="mb-3">Other</h5>
                                                            <div className="row">
                                                                <Form.Group className="col-md-6 form-group">
                                                                    <Form.Label htmlFor="gstvat">GST / VAT Tax:</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        id="gstvat"
                                                                        placeholder="GST / VAT Tax"
                                                                        onChange={(e) => (e.target.value)}
                                                                    />
                                                                </Form.Group>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Body>
                                    <div className="new-user-info">
                                        <h5 className="mb-3">Security</h5>
                                        <Row>
                                            <Form.Group className="col-md-4 form-group">
                                                <Form.Label htmlFor="email">Email:</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    id="email"
                                                    required
                                                    placeholder="Email"
                                                    autoComplete="new-password"
                                                    name="new-password-field"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-4 form-group">
                                                <Form.Label htmlFor="pass">Password:</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    id="pass"
                                                    required
                                                    placeholder="Password"
                                                    autoComplete="new-password"
                                                    name="new-password-field"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-4 form-group">
                                                <Form.Label htmlFor="rpass">Repeat Password:</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    id="rpass"
                                                    required
                                                    placeholder="Repeat Password"
                                                    value={repeatPassword}
                                                    onChange={(e) => setRepeatPassword(e.target.value)}
                                                />
                                            </Form.Group>

                                        </Row>
                                        <div className="checkbox">
                                            <label className="form-label">
                                                <input
                                                    type="checkbox"
                                                    className="me-2 form-check-input"
                                                    value=""
                                                    id="flexCheckChecked"
                                                />
                                                Enable Two-Factor-Authentication
                                            </label>
                                        </div>
                                        <Button onClick={handleSubmit} variant="btn btn-primary float-end">
                                            Save
                                        </Button>
                                    </div>

                                </Card.Body>
                            </Card>
                        </Form>
                    </Col>
                </Row>
            </Form>
        </Fragment>
    );
});

NewUser.displayName = "NewUser";
export default NewUser;
