import React, { memo, Fragment } from "react";

const fillstar = (
  <svg className="me-2" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M7.65079 1.0757L9.1351 4.05855C9.24446 4.27466 9.45317 4.42474 9.69389 4.45809L13.0279 4.94367C13.2226 4.97102 13.3994 5.07373 13.5187 5.23048C13.6367 5.38523 13.6874 5.58133 13.6587 5.77409C13.6354 5.93417 13.5601 6.08225 13.4447 6.19564L11.0288 8.5375C10.8521 8.70091 10.7721 8.94304 10.8148 9.17982L11.4096 12.4722C11.4729 12.8697 11.2095 13.2446 10.8148 13.3199C10.6521 13.3459 10.4854 13.3186 10.3387 13.2439L7.36472 11.6944C7.14401 11.583 6.88329 11.583 6.66257 11.6944L3.6886 13.2439C3.32319 13.438 2.87043 13.3059 2.66705 12.9457C2.5917 12.8023 2.56503 12.6389 2.5897 12.4795L3.18449 9.18649C3.22717 8.95037 3.14649 8.70691 2.97045 8.5435L0.554596 6.20298C0.267201 5.9255 0.258533 5.4686 0.535259 5.18112C0.54126 5.17512 0.547928 5.16845 0.554596 5.16178C0.669288 5.04505 0.819987 4.97102 0.982688 4.95167L4.31674 4.46543C4.55679 4.43141 4.7655 4.28267 4.87552 4.06522L6.3065 1.0757C6.43386 0.819572 6.69791 0.660157 6.98464 0.666827H7.074C7.32272 0.696843 7.53943 0.850921 7.65079 1.0757Z" fill="#FFD329"></path>
  </svg>
);

const unfill = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M7.65079 1.0757L9.1351 4.05855C9.24446 4.27466 9.45317 4.42474 9.69389 4.45809L13.0279 4.94367C13.2226 4.97102 13.3994 5.07373 13.5187 5.23048C13.6367 5.38523 13.6874 5.58133 13.6587 5.77409C13.6354 5.93417 13.5601 6.08225 13.4447 6.19564L11.0288 8.5375C10.8521 8.70091 10.7721 8.94304 10.8148 9.17982L11.4096 12.4722C11.4729 12.8697 11.2095 13.2446 10.8148 13.3199C10.6521 13.3459 10.4854 13.3186 10.3387 13.2439L7.36472 11.6944C7.14401 11.583 6.88329 11.583 6.66257 11.6944L3.6886 13.2439C3.32319 13.438 2.87043 13.3059 2.66705 12.9457C2.5917 12.8023 2.56503 12.6389 2.5897 12.4795L3.18449 9.18649C3.22717 8.95037 3.14649 8.70691 2.97045 8.5435L0.554596 6.20298C0.267201 5.9255 0.258533 5.4686 0.535259 5.18112C0.54126 5.17512 0.547928 5.16845 0.554596 5.16178C0.669288 5.04505 0.819987 4.97102 0.982688 4.95167L4.31674 4.46543C4.55679 4.43141 4.7655 4.28267 4.87552 4.06522L6.3065 1.0757C6.43386 0.819572 6.69791 0.660157 6.98464 0.666827H7.074C7.32272 0.696843 7.53943 0.850921 7.65079 1.0757Z" fill="#EBEEFD"></path>
    <path d="M6.99459 11.6114C6.86546 11.6154 6.73965 11.6501 6.62649 11.7121L3.66707 13.2581C3.30496 13.4309 2.87163 13.2968 2.66861 12.9505C2.59339 12.809 2.5661 12.6469 2.59139 12.4881L3.18248 9.20206C3.22242 8.9632 3.14254 8.72033 2.96881 8.55219L0.551877 6.21228C0.264987 5.93138 0.259661 5.47034 0.540561 5.18277C0.544555 5.17876 0.547883 5.17543 0.551877 5.17209C0.666367 5.05866 0.814138 4.98394 0.973226 4.96058L4.31007 4.46951C4.5517 4.43882 4.76138 4.28803 4.86788 4.06919L6.3183 1.04204C6.45609 0.797838 6.72035 0.652385 6.99992 0.667731C6.99459 0.865894 6.99459 11.4766 6.99459 11.6114Z" fill="#FFD329"></path>
  </svg>
);

const StarRating = memo(function StarRating({
  count,
  value,
  count1,
  inactiveColor = "#ddd",
  size = 24,
  activeColor = "#f00",
  onChange,
}) {
  // short trick
  const stars = Array.from({ length: count }, () => fillstar);
  const stars1 = Array.from({ length: count1 }, () => unfill);

  // Internal handle change function
  const handleChange = (value) => {
    onChange(value + 1);
  };

  return (
    <div>
      {stars.map((s, index) => {
        let style = inactiveColor;
        if (index < value) {
          style = activeColor;
        }
        return (
          <span
            className={"star"}
            key={index}
            style={{ color: style, width: size, height: size, fontSize: size }}
            onClick={() => handleChange(index)}
          >
            {s}
          </span>
        );
      })}
      {value}

      {stars1.map((s, index) => {
        let style = inactiveColor;
        if (index < value) {
          style = activeColor;
        }
        return (
          <span
            className={"star"}
            key={index}
            style={{ color: style, width: size, height: size, fontSize: size }}
            onClick={() => handleChange(index)}
          >
            {s}
          </span>
        );
      })}
      {value}
    </div>
  );
});

const RatingStar = memo((props) => {
  return (
    <Fragment>
      <StarRating count1={props.unfillStar} count={props.fillStar} />
    </Fragment>
  );
});

RatingStar.displayName = "RatingStar";
export default RatingStar;
