import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import Select from "react-select";
import { customStyles } from "../../../plugins/pages/select2";
import { useMyContext } from '../../../../../Context/MyContextProvider';
import axios from 'axios';
import JoditEditor from 'jodit-react';
import { useNavigate } from 'react-router-dom';
const MakeEvent = () => {
    const { api, UserList, authToken, userRole, UserData } = useMyContext();
    const navigate = useNavigate()
    const [validated, setValidated] = useState(false);
    const [users, setUsers] = useState([]);
    const [address, setAddress] = useState('');
    const [userId, setUserId] = useState('');
    const [category, setCategory] = useState('');
    const [name, setName] = useState('');
    const [country, setCountry] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');

    const [description, setDescription] = useState('');
    const [customerCareNumber, setCustomerCareNumber] = useState('');
    const [eventFeature, setEventFeature] = useState('');
    const [status, setStatus] = useState('');
    const [houseFull, setHouseFull] = useState('');
    const [smsOtpCheckout, setSmsOtpCheckout] = useState('');
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [countries, setCountries] = useState([]);
    const [isOrganizer, setIsOrganizer] = useState(false);

    const categoryList = [
        { value: 'Business Seminars', label: 'Business Seminars' },
        { value: 'Live Band', label: 'Live Band' },
        { value: 'Live Concert', label: 'Live Concert' },
        { value: 'DJ Night', label: 'DJ Night' },
        { value: 'Garba Night', label: 'Garba Night' },
        { value: 'Food festival', label: 'Food festival' },
        { value: 'Education Festival', label: 'Education Festival' },
        { value: 'Techno Fair', label: 'Techno Fair' },
        { value: 'Real Estate', label: 'Real Estate' },
        { value: 'Automotive Expo', label: 'Automotive Expo' },
        { value: 'Fun fair', label: 'Fun fair' }
    ];

    useEffect(() => {
        if (userRole === 'Organizer') {
            setUserId({ key: UserData?.id, label: UserData?.id })
            setIsOrganizer(true)
        }
        setUsers(UserList)
    }, []);

    useEffect(() => {
        axios.post(`https://api.first.org/data/v1/countries`)
            .then((res) => {
                //  console.log(res.data.data)
                const transformedData = Object.values(res.data.data).map(item => ({
                    label: item.country,
                    value: item.country,
                }));
                setCountries([{ label: 'Select State', value: 'Select State', isDisabled: true }, ...transformedData]);
            })
            .catch((err) => { // console.log(err)
            })
    }, [])
    useEffect(() => {
        setStates([{ label: 'Select State', value: 'Select State', isDisabled: true }]);
        if (country) {
            axios.post(`https://countriesnow.space/api/v0.1/countries/states`, { "country": country })
                .then((res) => {
                    const transformedData = res.data.data.states.map(state => ({
                        label: state.name,
                        value: state.name,
                    }));
                    setStates([{ label: 'Select State', value: 'Select State', isDisabled: true }, ...transformedData]);
                })
                .catch((err) => { })
        }
    }, [country])

    useEffect(() => {
        setCities([{ label: 'Select City', value: 'Select City' }])
        if (state) {
            axios.post(`https://countriesnow.space/api/v0.1/countries/state/cities`, { "country": country, "state": state })
                .then((res) => {
                    let data = res.data.data;
                    const transformedData = data.map(city => ({
                        label: city,
                        value: city,
                    }))
                    setCities([{ label: 'Select City', value: 'Select City', isDisabled: true }, ...transformedData])
                }).catch((err) =>
                    console.log(err)
                )
        }
    }, [state])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            console.log(form.checkValidity())
            event.stopPropagation();
        } else {
            // console.log(name)
            const formData = new FormData();
            formData.append('address', address);
            formData.append('user_id', JSON.stringify(userId));
            formData.append('category', category);
            formData.append('name', name);
            formData.append('country', country);
            formData.append('state', state);
            formData.append('city', city);
            formData.append('description', description);
            formData.append('customer_care_number', customerCareNumber);
            formData.append('event_feature', eventFeature);
            //formData.append('status', status);
            formData.append('house_full', houseFull);
            formData.append('sms_otp_checkout', smsOtpCheckout);

            await axios.post(`${api}create-event`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
                .then((res) => {
                    //setEventId(res.data.event?.id)
                    if (res.data.status) {
                        let id = res.data.event?.event_key
                        navigate('detail-info', { state: { id } })
                    }
                }).catch((err) =>
                    console.log(err)
                )
            setValidated(true);
        }
    }
    return (
        <>
            <Row>
                <Col sm="12" lg="8">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Event Primary Details</h4>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Form validated={validated} onSubmit={handleSubmit} className="needs-validation" noValidate>
                                <div className="form-card text-start">
                                    <Row>
                                        {!isOrganizer &&
                                            <Col md="4">
                                                <Form.Group>
                                                    <Form.Label>Organizer: *</Form.Label>
                                                    <Select
                                                        options={users}
                                                        styles={customStyles}
                                                        onChange={(e) => setUserId(e.value)}
                                                        required
                                                    />
                                                    <Form.Control.Feedback tooltip>
                                                        Looks good!
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Col>
                                        }
                                        <Col md="4">
                                            <Form.Group>
                                                <Form.Label>Category: *</Form.Label>
                                                <Select
                                                    options={categoryList}
                                                    styles={customStyles}
                                                    onChange={(e) => setCategory(e.value)}
                                                    required
                                                />
                                                <Form.Control.Feedback tooltip>
                                                    Looks good!
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group className="position-relative">
                                                <Form.Label htmlFor="validationTooltipUsername">Event Name: *</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Form.Control
                                                        type="text"
                                                        id="validationTooltipUsername"
                                                        aria-describedby="validationTooltipUsernamePrepend"
                                                        onChange={(e) => setName(e.target.value)}
                                                        required
                                                    />
                                                    <Form.Control.Feedback tooltip>
                                                        Looks good!
                                                    </Form.Control.Feedback>
                                                    <Form.Control.Feedback tooltip type="invalid">
                                                        Please enter event name.
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <Form.Label>Country: *</Form.Label>
                                                <Select
                                                    options={countries}
                                                    styles={customStyles}
                                                    onChange={(e) => setCountry(e.value)}
                                                    required
                                                />
                                                <Form.Control.Feedback tooltip>
                                                    Looks good!
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <Form.Label>State: *</Form.Label>
                                                <Select
                                                    options={states}
                                                    styles={customStyles}
                                                    onChange={(e) => setState(e.value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="4">
                                            <Form.Group>
                                                <Form.Label>City: *</Form.Label>
                                                <Select
                                                    options={cities}
                                                    styles={customStyles}
                                                    onChange={(e) => setCity(e.label)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Address: *</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    rows="3"
                                                    onChange={(e) => setAddress(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="12">
                                            <Form.Group>
                                                <Form.Label>Event Description: *</Form.Label>
                                                <JoditEditor
                                                    tabIndex={1}
                                                    value={description}
                                                    onChange={(value) => setDescription(value)}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md="6">
                                            <Form.Group>
                                                <Form.Label>Add Customer Care Number: *</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Customer Care Number"
                                                    value={customerCareNumber}
                                                    onChange={(e) => setCustomerCareNumber(e.target.value)}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Row>
                                            <Col md="3">
                                                <Form.Group className="form-group">
                                                    <Form.Label className="custom-file-input">Event Controls</Form.Label>
                                                    <Form.Check className="form-switch">
                                                        <Form.Check.Input
                                                            type="checkbox"
                                                            className="me-2"
                                                            id="flexSwitchCheckDefault"
                                                            checked={eventFeature}
                                                            onChange={(e) => setEventFeature(e.target.checked)}
                                                        />
                                                        <Form.Check.Label htmlFor="flexSwitchCheckDefault">
                                                            Event Features
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                            </Col>
                                            <Col md="3">
                                                <Form.Group className="form-group">
                                                    <Form.Label className="custom-file-input">&nbsp;</Form.Label>
                                                    <Form.Check className="form-switch">
                                                        <Form.Check.Input
                                                            type="checkbox"
                                                            className="me-2"
                                                            id="flexSwitchCheckDefault"
                                                            checked={status}
                                                            onChange={(e) => setStatus(e.target.checked)}
                                                        />
                                                        <Form.Check.Label htmlFor="flexSwitchCheckDefault">
                                                            Event Status
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                            </Col>
                                            <Col md="3">
                                                <Form.Group className="form-group">
                                                    <Form.Label className="custom-file-input">&nbsp;</Form.Label>
                                                    <Form.Check className="form-switch">
                                                        <Form.Check.Input
                                                            type="checkbox"
                                                            className="me-2"
                                                            id="flexSwitchCheckDefault"
                                                            checked={houseFull}
                                                            onChange={(e) => setHouseFull(e.target.checked)}
                                                        />
                                                        <Form.Check.Label htmlFor="flexSwitchCheckDefault">
                                                            House Full
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                            </Col>
                                            <Col md="3">
                                                <Form.Group className="form-group">
                                                    <Form.Label className="custom-file-input">&nbsp;</Form.Label>
                                                    <Form.Check className="form-switch">
                                                        <Form.Check.Input
                                                            type="checkbox"
                                                            className="me-2"
                                                            id="flexSwitchCheckDefault"
                                                            checked={smsOtpCheckout}
                                                            onChange={(e) => setSmsOtpCheckout(e.target.checked)}
                                                        />
                                                        <Form.Check.Label htmlFor="flexSwitchCheckDefault">
                                                            SMS OTP Checkout
                                                        </Form.Check.Label>
                                                    </Form.Check>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Row>
                                </div>
                                <Button type="submit" className="action-button float-end">
                                    Proceed to details
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default MakeEvent