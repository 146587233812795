import React, { memo, Fragment, useRef, useState, useEffect } from "react";

//react-bootstrap
import { Row, Col, Card, Button } from "react-bootstrap";

import $ from "jquery";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useMyContext } from "../../../../../Context/MyContextProvider";
import useDataTable from "../../../../../components/hooks/useDatatable";

const Events = memo(() => {
    const { api, formatDateTime, successAlert, UserData, authToken } = useMyContext();
    const navigate = useNavigate();
    const [users, setUsers] = useState([])


    const GetEvents = async () => {
        await axios.get(`${api}event-list/${UserData?.id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    setUsers(res.data.events)
                }
            }).catch((err) =>
                console.log(err)
            )
    }
    useEffect(() => {
        GetEvents()
    }, [])

    const formatDateRange = (dateRange) => {
        if (!dateRange) return '';

        const dates = dateRange.split(',');
        if (dates.length !== 2) return dateRange; // Fallback if the format is unexpected

        const [startDate, endDate] = dates;
        return `${startDate} to ${endDate}`;
    };
    const listtableRef = useRef(null);
    const columns = useRef([
        {
            data: null, // No direct data mapping
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1 // Use index + 1 as OrderId
        },
        { data: "name", title: "Name" },
        { data: "category", title: "Category" },
        { data: "user.name", title: "Organizer" },
        {
            data: "date_range",
            title: "Event Dates",
            render: function (data) {
                return formatDateRange(data);
            },
        },
        { data: "event_type", title: "Ticket Type" },
        {
            data: "event_status",
            title: "Status",
            render: function (data) {
                if (data) {
                    let badgeClass = '';
                    let badgeText = '';
                    switch (data) {
                        case 1:
                            badgeClass = 'bg-success';
                            badgeText = 'Ongoing';
                            break;
                        case 2:
                            badgeClass = 'bg-info';
                            badgeText = 'Upcoming';
                            break;
                        case 3:
                            badgeClass = 'bg-warning';
                            badgeText = 'Finished';
                            break;
                        default:
                            badgeClass = 'bg-secondary';
                            badgeText = 'Unknown';
                    }
                    return `<span class="badge p-1 ${badgeClass}">${badgeText}</span>`;
                }
            },
        },
        {
            data: "created_at",
            title: "Created At",
            render: function (data) {
                if (data) {
                    return formatDateTime(data);
                }
            },
        },

        {
            data: null,
            orderable: false,
            searchable: false,
            title: "Action",
            render: function (data) {
                return `<div class="flex align-items-center list-user-action">
                            <a href="/events/${(data?.name)?.replace(/\s+/g, '-')}/${data.event_key}" target="__blank">
                                <button class="btn btn-sm btn-icon btn-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Print" data-id=${data?.id} data-table="action">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" width="20" height="32" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M15.1614 12.0531C15.1614 13.7991 13.7454 15.2141 11.9994 15.2141C10.2534 15.2141 8.83838 13.7991 8.83838 12.0531C8.83838 10.3061 10.2534 8.89111 11.9994 8.89111C13.7454 8.89111 15.1614 10.3061 15.1614 12.0531Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M11.998 19.355C15.806 19.355 19.289 16.617 21.25 12.053C19.289 7.48898 15.806 4.75098 11.998 4.75098H12.002C8.194 4.75098 4.711 7.48898 2.75 12.053C4.711 16.617 8.194 19.355 12.002 19.355H11.998Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><circle cx="12.1747" cy="11.8891" r="2.63616" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></circle></svg>
                                </button>
                             </a>
                             <a href="events/edit/${(data?.event_key)}">
                                <button class="btn btn-sm btn-icon btn-warning" data-bs-toggle="tooltip" data-bs-placement="top" title="Print" data-id=${data?.id} data-method="Print" data-table="action">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" width="20" height="32" viewBox="0 0 24 24"><path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><circle cx="12.1747" cy="11.8891" r="2.63616" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></circle></svg>
                                </button>
                            </a>
                           
                            <button class="btn btn-sm btn-icon btn-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Print" data-id=${data?.id} data-method="Delete" data-table="action">
                               <svg fill="none" xmlns="http://www.w3.org/2000/svg" class="icon-20" width="20" height="32" viewBox="0 0 24 24"><path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></path><circle cx="12.1747" cy="11.8891" r="2.63616" stroke="currentColor" strokeWidth="1.5" stroke-linecap="round" strokeLinejoin="round"></circle></svg>
                            </button>
                          </div>
                        `;
            },
        },
    ]);
    useDataTable({
        tableRef: listtableRef,
        columns: columns?.current,
        data: users?.length > 0 && users,
        actionCallback: (data) => {
            switch (data.method) {
                case 'Delete':
                    HandleDelete(data?.id);
                    break;
                case 'edit':
                    console.log("hello");
                    break;
                case 'disable':
                    //  DeleteBooking(data?.id);
                    break;
                default:
                    break;
            }
        }
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });


    const HandleDelete = async (id) => {
        if (id) {
            await axios.delete(`${api}delete-event/${id}`)
                .then((res) => {
                    if (res.data.status) {
                        GetEvents()
                    }
                }).catch((err) =>
                    console.log(err)
                )
        }
    }


    return (
        <Fragment>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between">
                            <div className="header-title">
                                <h4 className="card-title">Events</h4>
                            </div>
                            <div className="button">
                                <h4 className="card-title">
                                    <Link to={'new'}>
                                        <Button className="me-4 hvr-icon-sink-away hvr-curl-top-right border-0 d-flex align-content-center justify-content-center">
                                            Create New Event
                                            <svg
                                                className="size-28 hvr-icon ms-2"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M7.33 2H16.66C20.06 2 22 3.92 22 7.33V16.67C22 20.06 20.07 22 16.67 22H7.33C3.92 22 2 20.06 2 16.67V7.33C2 3.92 3.92 2 7.33 2ZM12.82 12.83H15.66C16.12 12.82 16.49 12.45 16.49 11.99C16.49 11.53 16.12 11.16 15.66 11.16H12.82V8.34C12.82 7.88 12.45 7.51 11.99 7.51C11.53 7.51 11.16 7.88 11.16 8.34V11.16H8.33C8.11 11.16 7.9 11.25 7.74 11.4C7.59 11.56 7.5 11.769 7.5 11.99C7.5 12.45 7.87 12.82 8.33 12.83H11.16V15.66C11.16 16.12 11.53 16.49 11.99 16.49C12.45 16.49 12.82 16.12 12.82 15.66V12.83Z"
                                                    fill="currentColor"
                                                ></path>
                                            </svg>
                                        </Button>
                                    </Link>
                                </h4>
                            </div>
                        </Card.Header>
                        <Card.Body className="px-0">
                            <div className="table-responsive">
                                <table
                                    id="datatable-ecom"
                                    ref={listtableRef}
                                    className="data-tables table custom-table movie_table"
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    );
});

Events.displayName = "Events";
export default Events;
