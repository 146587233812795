import React, { memo, Fragment } from "react";

//Component
import Socialprofilevideo from "../components/profile-video";
import { Row } from "react-bootstrap";

const Profilevideo = memo(() => {
  return (
    <Fragment>
      <Row className="my-5">
        <div className="d-grid gap-3 d-grid-template-1fr-19">
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/OGuX3lO5Gjs"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/DL93L26UsI0"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/gGUCCh8XedI"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/1hUPsry0LjQ"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/PHF0lmDBmXo"
            title="YouTube video player"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/d1ri9sCseTk"
            title="YouTube video player"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/J7tNLM2iBlE"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/8yiFq4FYWlQ"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/78mPwkO6aek"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/3OMj6nqDuAA"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/VeDdpy4CdeM"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/XvtKsTbrPz0"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/27hOzBaFbJU"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/y1rglaseZ-k"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/RntJ61yWrRI"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/dVzFhSu5Mm8"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/11uVhnjxjRA"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/f-t7RA6gdjE"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/dctRWhSabrU"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/Onekf3kAMog"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
          <Socialprofilevideo
            profilevideosrc="https://www.youtube.com/embed/--6zKnvpGGk"
            profilevideodetail1="60"
            profilevideodetail2="30"
            profilevideodetail3="10"
          />
        </div>
      </Row>
    </Fragment>
  );
});

Profilevideo.displayName = "Profilevideo";
export default Profilevideo;
