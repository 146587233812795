import React, { useEffect, useState } from 'react'
import { Card, Col, Row, Form, Button } from 'react-bootstrap'
import { useMyContext } from '../../../../Context/MyContextProvider'
import axios from 'axios'
const AdminSetting = () => {
    const { api, successAlert ,authToken} = useMyContext();
    const [appName, setAppName] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaTag, setMetaTag] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [logo, setLogo] = useState('');
    const [favicon, setFavicon] = useState('');
    const [copyright, setCopyright] = useState('');
    const [copyrightLink, setCopyrightLink] = useState('');

    const GetMailConfig = async () => {
        try {
            const res = await axios.get(`${api}settings`, {
                headers: {
                  'Authorization': 'Bearer ' + authToken,
                }
              });
            if (res.data.status) {
                const configData = res.data.data;
                setAppName(configData?.app_name || '');
                setMetaTitle(configData?.meta_title || '');
                setMetaTag(configData?.meta_tag || '');
                setMetaDescription(configData?.meta_description || '');
                setLogo(configData?.logo || '');
                setFavicon(configData?.favicon || '');
                setCopyright(configData?.copyright || '');
                setCopyrightLink(configData?.copyright_link || '');

            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        GetMailConfig()
    }, []);

    const changeFavicon = (newFaviconUrl) => {
        const favicon = document.querySelector('link[rel="icon"]');
        if (favicon) {
          favicon.href = newFaviconUrl;
        } else {
          // Create a new link element for the favicon
          const newFavicon = document.createElement('link');
          newFavicon.rel = 'icon';
          newFavicon.href = newFaviconUrl;
          document.head.appendChild(newFavicon);
        }
    }
    
    const handleAppConfig = async (e) => {
        try {
            e.preventDefault()
            console.log(logo)
            const formData = new FormData();
            formData.append('app_name', appName);
            formData.append('meta_title', metaTitle);
            formData.append('meta_tag', metaTag);
            formData.append('meta_description', metaDescription);
            formData.append('copyright', copyright);
            formData.append('copyright_link', copyrightLink);
            formData.append('logo', logo);
            formData.append('favicon', favicon);
            // if (logo) {
            // }
            // if (favicon) {
            // }
            // Append additional form data as needed

            const res = await axios.post(`${api}setting`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                    'Content-Type': 'multipart/form-data'
                }
            });
            //
            if (res.data.status) {
                successAlert('Success', 'App Configuration Stored Successfully');
            }
        } catch (err) {
            console.log(err);
        }
    };


    return (
        <Row>
            <Col lg="8">
                <Card>
                    <Card.Header>
                        <h4 className="card-title">Admin Settings</h4>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Row>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Logo</Form.Label>
                                        <Form.Control type="file" id="logoFile" onChange={(e)=>setLogo(e.target.files[0])} />
                                    </Form.Group>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Favicon</Form.Label>
                                        <Form.Control type="file" id="faviconFile" onChange={(e)=>setFavicon(e.target.files[0])} />
                                    </Form.Group>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>App Name</Form.Label>
                                        <Form.Control type="text" placeholder="App name" value={appName} onChange={(e) => setAppName(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="6">
                                    {/* Placeholder for additional fields */}
                                </Col>
                                <hr className="hr-horizontal" />
                                <Col lg="12">
                                    <h5>Copyright</h5>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Copyright Text</Form.Label>
                                        <Form.Control type="text" placeholder="" value={copyright} onChange={(e) => setCopyright(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Copyright Url</Form.Label>
                                        <Form.Control type="text" placeholder="" value={copyrightLink} onChange={(e) => setCopyrightLink(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <hr className="hr-horizontal" />
                                <Col lg="12">
                                    <h5>SEO</h5>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Meta Title</Form.Label>
                                        <Form.Control type="text" placeholder="" value={metaTitle} onChange={(e) => setMetaTitle(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="6">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Meta Tag</Form.Label>
                                        <Form.Control type="text" placeholder="" value={metaTag} onChange={(e) => setMetaTag(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col lg="12">
                                    <Form.Group className="mb-3 form-group">
                                        <Form.Label>Meta Description</Form.Label>
                                        <Form.Control as="textarea" placeholder="" value={metaDescription} onChange={(e) => setMetaDescription(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <div className='d-flex justify-content-end'>
                                    <Button type="button" onClick={(e)=>handleAppConfig(e)}>Submit</Button>
                                </div>
                            </Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default AdminSetting