import React, { useState, memo, Fragment, useEffect, useRef } from "react";

// Router
import { Link } from "react-router-dom";

// React-bootstrap
import { Button, Row, Col, Table, Card, Modal, Form, Accordion } from "react-bootstrap";

import axios from "axios";

import currencyData from '../../../../JSON/currency.json';

import QRCode from "react-qr-code";
import { useReactToPrint } from 'react-to-print';
import { useMyContext } from "../../../../Context/MyContextProvider";
import CustomCounter from "../Events/Counter/customCounter";
import Select from 'react-select';
import PosEvents from "./PosEvents";
const POS = memo(() => {
    const { api, UserData, isMobile, formatDateTime, ErrorAlert, authToken } = useMyContext();
    const [eventID, setEventID] = useState(true);
    const [isCheckOut, setIsCheckOut] = useState(true);
    const [currentStep, setCurrentStep] = useState('checkout');
    const [event, setEvent] = useState([]);
    const [tickets, setTickets] = useState([]);
    const [currencyMaster, setCurrencyMaster] = useState([]);
    const [selectedTickets, setSelectedTickets] = useState([]);
    const [bookingHistory, setBookingHistory] = useState([]);
    const [subtotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [ticketCurrency, setTicketCurrency] = useState('₹');
    const [totalTax, setTotalTax] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [baseAmount, setBaseAmount] = useState(0);
    const [centralGST, setCentralGST] = useState(0);
    const [stateGST, setStateGST] = useState(0);
    const [number, setNumber] = useState('');
    const [name, setName] = useState('');
    const [disableChoice, setDisableChoice] = useState(false);
    const [ticketSummary, setTicketSummary] = useState([]);
    const [bookingData, setBookingData] = useState([]);
    const [selectedTicketID, setSelectedTicketID] = useState(null);
    const [discountType, setDiscountType] = useState('fixed');
    const [discountValue, setDiscountValue] = useState();
    const [commissionType, setCommissionType] = useState('');
    const [resetCounterTrigger, setRsetCounterTrigger] = useState(0)

    const [bookings, setBookings] = useState([])
    const GetBookings = async () => {
        await axios.get(`${api}pos-bookings/${UserData?.id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    setBookings(res.data)
                }
            }).catch((err) =>
                console.log(err)
            )
    }
    const getTicketData = async (id) => {
        await axios.get(`${api}event-detail/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    setEvent(res.data.events)
                    setTickets(res.data.events?.tickets)
                }
            }).catch((err) =>
                console.log(err)
            )
    }


    useEffect(() => {
        setCurrencyMaster(currencyData)
        if (isMobile) {
            setIsCheckOut(true)
        }
    }, [isMobile])



    const getCurrencySymbol = (currency) => {
        if (currencyMaster && currency) {

            if (currencyMaster.hasOwnProperty(currency)) {
                let symbol = currencyMaster[currency]?.symbol;
                return symbol;
            }
        }
    }

    const getTicketCount = (quantity, category, price, id) => {
        setSelectedTickets(prevTickets => {
            const existingIndex = prevTickets.findIndex(ticket => ticket.category === category);
            if (existingIndex !== -1) {
                // If category exists, update its quantity
                const updatedTickets = [...prevTickets];
                updatedTickets[existingIndex].quantity = quantity;
                return updatedTickets;
            } else {
                return [...prevTickets, { category, quantity, price, id }];
            }
        });
    }

    useEffect(() => {
        const isAnyTicketSelected = selectedTickets?.some(ticket => ticket?.quantity > 0);
        if (isAnyTicketSelected) {
            const total = selectedTickets.reduce((acc, ticket) => {
                let price = ticket?.sale === 'true' ? ticket?.sale_price : ticket?.price
                const totalPriceForCategory = price * ticket.quantity;
                return acc + totalPriceForCategory;
            }, 0);
            setSubTotal(total);
            const firstSelectedTicket = selectedTickets.find(ticket => ticket?.quantity > 0);
            setTickets(tickets?.filter((item) => item?.id === firstSelectedTicket?.id));
        } else {
            setSubTotal(0)
            setBaseAmount(0)
            setCentralGST(0)
            setStateGST(0)
            setTotalTax(0)
            setGrandTotal(0)
            setTickets(event?.tickets)
        }
    }, [selectedTickets]);


    useEffect(() => {
        if (subtotal) {
            // setBaseAmount(subtotal * 10 / 100)
            setCentralGST(baseAmount * 9 / 100)
            setStateGST(baseAmount * 9 / 100)
            setTotalTax((centralGST + stateGST + baseAmount)?.toFixed(2))
        }

        if (((subtotal + totalTax) - discount) > 0) {
            let total = (subtotal + +totalTax) - discount
            setGrandTotal(total?.toFixed(2))
        }
    }, [subtotal, totalTax, discount, baseAmount, centralGST, stateGST]);

    const handleDiscount = () => {
        let disc = 0;
        if (discountValue) {
            if (discountType === 'fixed') {
                disc = discountValue;
                setDiscount(disc)
                setDisableChoice(true)
            } else if (discountType === 'percentage') {
                disc = subtotal * discountValue / 100
                setDiscount(disc)
                setDisableChoice(true)
            }
            console.log(disc)
            setGrandTotal(grandTotal - disc)
        }
    };

    useEffect(() => {
        setDisableChoice(false)
        if (discountValue) {
            setDiscount(0);
        }
    }, [discountValue, discountType]);


    useEffect(() => {
        if (bookingHistory.length > 0) {
            // Group tickets by category and sum quantities
            const ticketMap = bookingHistory.reduce((acc, booking) => {
                const ticket = event.tickets?.find(item => item.id === booking.ticket_id);
                if (ticket) {
                    if (!acc[ticket.name]) {
                        acc[ticket.name] = { ...ticket, quantity: 0 };
                    }
                    acc[ticket.name].quantity += 1; // Assuming each booking represents one ticket
                }
                return acc;
            }, {});

            // Convert the map to an array
            const ticketsData = Object.values(ticketMap);
            console.log(ticketsData);
            setTicketSummary(ticketsData);
        }
    }, [bookingHistory, event.tickets]);



    //model states
    const [showPrintModel, setShowPrintModel] = useState(false);
    const printRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });




    const [activeKey, setActiveKey] = useState('0');
    useEffect(() => {
        GetBookings()
        const accordionButton = document.querySelector('.accordion-button');
        if (accordionButton) {
            accordionButton.style.backgroundColor = 'transparent';
        }
    }, []);

    const handleButtonClick = (id) => {
        setRsetCounterTrigger(resetCounterTrigger + 1)
        getTicketData(id)
        setActiveKey(null);
    };
    const closePrintModel = () => {
        resetfields()
        setShowPrintModel(false)
        setRsetCounterTrigger(resetCounterTrigger + 1)
    }
    const handleBooking = async () => {
        const validTickets = selectedTickets.filter(ticket => ticket.quantity > 0);
        // console.log(validTickets)
        if (validTickets[0]?.quantity === undefined) {
            ErrorAlert('Please Select A Ticket')
        } else {
            const requestData = {
                user_id: UserData?.id,
                number: number,
                name: name,
                tickets: validTickets,
                discount: discount,
                amount: grandTotal
            };
            try {
                // Book tickets
                const res = await axios.post(`${api}book-pos/${eventID}`, requestData, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    }
                });
                if (res.data.status) {
                    resetfields()
                    setBookingData(res.data?.bookings);
                    setShowPrintModel(true)
                    GetBookings()
                }
            } catch (err) {
                console.log(err);
            }
        }
    };
    const resetfields = () =>{
        setDiscountValue('')
        setName('')
        setNumber('')
        setDiscount(0)
        setDisableChoice(false)
    }
    return (
        <Fragment>
            {/* print model  */}
            <Modal show={showPrintModel} onHide={() => closePrintModel()} size="sm">
                <Modal.Header closeButton>
                    <Modal.Title>Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body ref={printRef}>
                    <div style={{ textAlign: 'center', color: "black", fontWeight: 'bold' }}>
                        <h5>{event?.name}</h5>
                        <div className='qr'>
                            <QRCode
                                size={150}
                                style={{ height: "auto" }}
                                className=''
                                value={bookingData?.token}
                            />
                        </div>
                        <span>{bookingData?.id}</span>
                        <p>
                            {formatDateTime(bookingData?.created_at)}
                        </p>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-black">Qty</th>
                                    <th className="text-black">Ticket Name</th>
                                    <th className="text-black">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-black">{bookingData?.quantity}</td>
                                    <td className="text-black">{bookingData?.ticket?.name}</td>
                                    <td className="text-black">{subtotal}</td>
                                </tr>
                            </tbody>

                        </table>
                        <hr />
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td className="text-black p-0 text-end">SUBTOTAL</td>
                                    <td className="text-black py-0 text-end">₹{subtotal}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td className="text-black p-0 text-end">TOTAL TAX</td>
                                    <td className="text-black py-0 text-end">₹{totalTax}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>
                                    <td className="text-black p-0 text-end">DISCOUNT</td>
                                    <td className="text-black py-0 text-end">₹{discount}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr>

                                    <td className="text-black text-end pe-0">TOTAL</td>
                                    <td className="text-black text-end">₹{grandTotal}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p style={{ fontSize: '14px' }}>
                            Thank You for Payment Type<br />
                            Please Come Again
                        </p>
                        <p style={{ fontSize: '14px' }}>
                            Powered by - GetYourTicket.co.in
                        </p>
                        <hr />
                        <p>- - - - - - - - - -</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => closePrintModel()}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handlePrint()}>
                        Print Invoice
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* print model end */}
            {
                (isMobile && isCheckOut) &&
                <div className="d-flex w-100  flex-wrap gap-4 p-0  justify-content-center bg-danger"
                    style={{
                        position: 'fixed',
                        left: '0',
                        zIndex: '99',
                        bottom: '0',
                        // background: '#f16a1b'
                    }}
                >
                    <div className="d-flex align-content-center w-100" onClick={() => handleBooking()}>
                        <Link
                            to=""
                            className="btn text-white d-flex align-items-center py-4 w-100 justify-content-center p-0 bg-warning"
                            style={{ borderRadius: '0' }}
                        >
                            <strong>Amount :</strong> {ticketCurrency}{grandTotal}
                        </Link>
                        <Link
                            to=""
                            className="btn btn-primary d-flex align-items-center py-4 w-100 justify-content-center p-0"
                            style={{ borderRadius: '0' }}
                        >
                            Checkout
                        </Link>
                    </div>
                </div>
            }
            <Row>
                <Col lg="12">
                    <Card>
                        <Card.Body className="py-0">
                            <Accordion flush className="p-0" activeKey={activeKey} onSelect={(e) => setActiveKey(e)}>
                                <Accordion.Item eventKey="0" className="bg-transparent">
                                    <Accordion.Header>Events</Accordion.Header>
                                    <Accordion.Body className="bg-transparent p-0 pt-3">
                                        <Row>
                                            <Col lg="12">
                                                <Form>
                                                    <Row>
                                                        <Col lg="12">
                                                            <Form.Group className="mb-3 form-group input-group search-input w-100">
                                                                <input
                                                                    type="search"
                                                                    className="form-control"
                                                                    placeholder="Search Your Event..."
                                                                />
                                                                <span className="input-group-text">
                                                                    <svg
                                                                        width="18"
                                                                        viewBox="0 0 24 24"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                    >
                                                                        <circle
                                                                            cx="11.7669"
                                                                            cy="11.7666"
                                                                            r="8.98856"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></circle>
                                                                        <path
                                                                            d="M18.0186 18.4851L21.5426 22"
                                                                            stroke="currentColor"
                                                                            strokeWidth="1.5"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                        ></path>
                                                                    </svg>
                                                                </span>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </Col>
                                            <Col lg="12">
                                                <PosEvents handleButtonClick={handleButtonClick} />
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg="8">
                    <Card>
                        <div className="card-header d-flex align-content-center justify-content-between">
                            <h4> {event?.name}</h4>
                            {/* <h4>{event?.name}</h4> */}

                        </div>
                        <Card.Body className="p-0">
                            <Table responsive className="mb-0">
                                <tbody>
                                    {tickets?.map((item, index) => {
                                        return (
                                            <tr data-item="list" key={index} className={`${(item.sold_out === 'true' || item.donation === 'true') && 'opacity-50'}`} style={{ pointerEvents: (item.sold_out === 'true' || item.donation === 'true') && 'none' }}>
                                                <td>
                                                    <div className="d-flex align-items-center gap-4">
                                                        <div>
                                                            <h6 className="mb-3">{item.name}{'  '}
                                                                <span className="text-danger">
                                                                    {item.sold_out === 'true' ? 'Booking Closed' : item.donation === 'true' && 'Booking Not Started Yet'}
                                                                </span>
                                                            </h6>
                                                            <p className="mb-1 d-flex gap-2">Price: {getCurrencySymbol(item.currency)}
                                                                {
                                                                    item?.sale === 'true' ?
                                                                        <>
                                                                            <span className="mb-0" style={{ textDecorationLine: 'line-through', textDecorationStyle: 'solid' }}>
                                                                                {'₹' + item?.price}
                                                                            </span>
                                                                            <span className="mb-0">
                                                                                {'₹' + item?.sale_price}
                                                                            </span>
                                                                        </>
                                                                        :
                                                                        item?.price
                                                                }
                                                            </p>
                                                            {/* <p className="mb-1">Size: {item.size}</p> */}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <CustomCounter
                                                        getTicketCount={getTicketCount}
                                                        category={item.name}
                                                        price={item?.sale === 'true' ? item?.sale_price : item?.price}
                                                        limit={10}
                                                        resetCounterTrigger={resetCounterTrigger}
                                                        ticketID={item.id}
                                                        disabled={selectedTicketID !== null && selectedTicketID !== item.id}
                                                    />
                                                </td>
                                                <td>
                                                    <div className="d-flex gap-3">
                                                        <p className="text-decoration-line-through mb-0">
                                                        </p>
                                                        <Link to="#" className="text-decoration-none">
                                                            {getCurrencySymbol(item.currency)}  {selectedTickets.map((ticket) =>
                                                                ticket.category === item.name &&
                                                                (item?.sale === 'true' ? item?.sale_price : item?.price) * ticket.quantity
                                                            )}
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg="4">
                    <Card>
                        <div className="d-flex  gap-2 justify-content-center">
                            <div>
                                Bookings :<span className="text-secondary"> {bookings?.allbookings?.length ?? 0}</span>
                            </div>
                            <div>
                                Amt :<span className="text-danger"> ₹{(parseInt(bookings?.amount) ?? 0).toFixed(2)}</span>
                            </div>
                            <div>
                                Disc :<span className="text-primary"> ₹{(parseInt(bookings?.discount) ?? 0).toFixed(2)}</span>
                            </div>
                        </div>
                        <div className="card-header d-flex align-content-center justify-content-between">
                            <h4 className="mb-0">Checkout</h4>
                        </div>
                        <Card.Body>
                            <div className="border-bottom">
                                <div className="input-group mb-3">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Customer Name"
                                        aria-label="Name"
                                        aria-describedby="name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Customer Number"
                                        aria-label="Number"
                                        aria-describedby="number"
                                        value={number}
                                        onChange={(e) => setNumber(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="mt-4">
                                <div className="d-flex justify-content-between mb-4">
                                    <h6>Sub Total</h6>
                                    <h6 className="text-primary">{ticketCurrency}{subtotal}</h6>
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <h6>Discount</h6>
                                    <h6 className="text-success">{ticketCurrency}{discount}</h6>
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <h6>Base Amount</h6>
                                    <h6 className="text-success">{ticketCurrency}{baseAmount}</h6>
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <h6>Central GST (CGST) @ 9%</h6>
                                    <h6 className="text-success">{ticketCurrency}{centralGST}</h6>
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <h6>State GST (SGST) @ 9%</h6>
                                    <h6 className="text-success">{ticketCurrency}{centralGST}</h6>
                                </div>
                                <div className="d-flex justify-content-between mb-4">
                                    <h6>Convenience fees</h6>
                                    <h6 className="text-success">{ticketCurrency}{totalTax}</h6>
                                </div>
                                <div className="border-bottom">
                                    <div className="input-group mb-3">
                                        <Form.Select
                                            aria-label="Default select example"
                                            value={discountType}
                                            onChange={(e) => setDiscountType(e.target.value)}
                                        >
                                            <option value="fixed">Fixed</option>
                                            <option value="percentage">Percentage</option>
                                        </Form.Select>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Add Discount"
                                            aria-label="value"
                                            aria-describedby="CouponCode"
                                            value={discountValue}
                                            onChange={(e) => setDiscountValue(e.target.value)}
                                        />
                                        <Button
                                            className="btn btn-primary"
                                            type="button"
                                            id="CouponCode"
                                            disabled={disableChoice}
                                            onClick={() => handleDiscount()}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4">

                                <div className="d-flex justify-content-between mb-4">
                                    <h6 className="mb-0">Order Total</h6>
                                    <h5 className="text-primary mb-0">
                                        {ticketCurrency} {grandTotal}
                                    </h5>

                                </div>

                                {/* <div className="alert border-primary rounded border-1 mb-4">
                                            <div className="d-flex justify-content-between align-items-center ">
                                                <h6 className="text-primary mb-0">
                                                    Total Savings on this order
                                                </h6>
                                                <h6 className="text-primary mb-0">
                                                    <b>{ticketCurrency}{discount}</b>
                                                </h6>
                                            </div>
                                        </div> */}
                                {
                                    !isMobile &&
                                    <div className="d-flex">
                                        <Button
                                            id="place-order"
                                            to="#"
                                            // onClick={orderSummary}
                                            onClick={handleBooking}
                                            variant="primary d-block mt-3 next w-100"
                                        >
                                            Checkout
                                        </Button>
                                    </div>
                                }
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment >
    );
});

POS.displayName = "POS";
export default POS;
