import React, { Fragment, memo } from "react";

const Testiominal = memo((props) => {
  return (
    <Fragment>
      <p className=" mb-5 test-text">{props.testText}</p>
      <img src={props.testImage} alt={props.testImage} className="img-fluid rounded-pill user-test-img mb-2"></img>
      <div className="mb-2">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z" fill="#FDB022"></path>
          <defs>
            <rect width="16" height="16" fill="white"></rect>
          </defs>
        </svg>
        <svg className="ms-1" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z" fill="#FDB022"></path>
          <defs>
            <rect width="16" height="16" fill="white"></rect>
          </defs>
        </svg>
        <svg className="ms-1" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z" fill="#FDB022"></path>
          <defs>
            <rect width="16" height="16" fill="white"></rect>
          </defs>
        </svg>
        <svg className="ms-1" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z" fill="#FDB022"></path>
          <defs>
            <rect width="16" height="16" fill="white"></rect>
          </defs>
        </svg>
        <svg className="ms-1" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path d="M7.63067 1.28789C7.76731 0.959376 8.23272 0.959376 8.36936 1.28789L10.0222 5.26187C10.0798 5.40037 10.2101 5.495 10.3596 5.50698L14.6498 5.85093C15.0045 5.87936 15.1483 6.32197 14.8781 6.55343L11.6094 9.35344C11.4954 9.45104 11.4457 9.60416 11.4805 9.75L12.4791 13.9366C12.5617 14.2826 12.1852 14.5562 11.8815 14.3707L8.20848 12.1273C8.08048 12.0491 7.91951 12.0491 7.7915 12.1273L4.11845 14.3707C3.81481 14.5562 3.43831 14.2826 3.52087 13.9366L4.51951 9.75C4.55431 9.60416 4.50456 9.45104 4.39065 9.35344L1.12193 6.55343C0.851714 6.32197 0.995529 5.87936 1.35019 5.85093L5.64043 5.50698C5.78995 5.495 5.92019 5.40037 5.9778 5.26187L7.63067 1.28789Z" fill="#FDB022"></path>
          <defs>
            <rect width="16" height="16" fill="white"></rect>
          </defs>
        </svg>
      </div>
      <h6 className="text-secondary mb-0">{props.testOwner}</h6>
      <p className="mb-0 text-primary">{props.testSubtitle}</p>
    </Fragment>
  );
});
export default Testiominal;