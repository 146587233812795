import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Nav, Row, Tab, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useDataTable from '../../../../components/hooks/useDatatable';
import $ from "jquery";
import { useMyContext } from '../../../../Context/MyContextProvider';
import axios from 'axios';

const EventReports = () => {
    const { api, UserData, authToken } = useMyContext();
    const [report, setReport] = useState();
    const listtableRef = useRef(null);

    const GetBookings = async () => {
        await axios
            .get(`${api}event-reports/${UserData?.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            })
            .then((res) => {
                setReport(res.data.data);
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        GetBookings();
    }, []);

    const columns = useRef([
        {
            data: null, // No direct data mapping
            orderable: false,
            title: "#",
            render: (data, type, row, meta) => meta.row + 1 // Use index + 1 as OrderId
        },
        {
            data: null,
            title: "Event",
            render: function (row) {
                // return `<p title=${row?.event_name}>${truncateString(row?.event_name)}</p>`;
                return `<p title=${row?.event_name}>${row?.event_name}</p>`;
            },
        },
        {
            data: null,
            title: "Organizer",
            render: function (row) {
                return row?.organizer;
            },
        },
        {
            data: null,
            title: "Avail Ticket",
            render: function (row) {
                return row?.ticket_quantity;
            },
        },
        {
            data: null,
            title: "Online",
            render: function (row) {
                return row?.non_agent_bookings;
            },
        },
        {
            data: null,
            title: "Agent",
            render: function (row) {
                return row?.agent_bookings;
            },
        },
        {
            data: null,
            title: "POS",
            render: function (row) {
                return (
                    row?.pos_bookings_quantity
                );
            },
        },
        {
            data: null,
            title: "Total T",
            render: function (row) {
                return (row?.non_agent_bookings)+ (row?.agent_bookings) + (row?.pos_bookings_quantity);
            },
        },
        {
            data: null,
            title: "Chek-ins",
            render: function (row) {
                return row?.total_ins;
            },
        },
        {
            data: null,
            title: "Online Sale",
            render: function (row) {
                return '₹ '+(row?.online_base_amount).toFixed(2);
            },
        },
        {
            data: null,
            title: "Agent Sale",
            render: function (row) {
                return (row?.online_base_amount).toFixed(2);
            },
        },
        {
            data: null,
            title: "Agent + Pos",
            render: function (row) {
                return (row?.agent_base_amount).toFixed(2);
            },
        },
        {
            data: null,
            title: "Disc",
            render: function (row) {
                return ((row?.online_discount)+(row?.pos_discount)+(row?.agent_discount)).toFixed(2);
            },
        },
        {
            data: null,
            title: "Con Fees",
            render: function (row) {
                return ((row?.online_convenience_fee) + (row.pos_convenience_fee)).toFixed(2);
            },
        },
    ]);
    useDataTable({
        tableRef: listtableRef,
        columns: columns.current,
        data: report,
        actionCallback: (data) => {
            switch (data.method) {
                case "Print":
                    // HandleSendTicket(data?.id);
                    break;
                case "edit":
                    console.log("hello");
                    break;
                case "disable":
                    // DeleteBooking(data?.id);
                    break;
                default:
                    break;
            }
        },
    });

    if ($.fn.DataTable.isDataTable("#datatable-ecom")) {
        $("#datatable-ecom").DataTable().destroy();
    }
    $("#datatable-ecom").DataTable({
        createdRow: function (row, data, dataIndex) {
            $(row).find("td:eq(1), td:eq(3)").css("text-align", "center");
        },
    });
    return (
        <Row>
            <Col lg="12" md="12">
                <Card className="card-block">
                    <Card.Header>
                        <h4 className="">
                            Events Report
                        </h4>
                    </Card.Header>
                    <Card.Body className='px-0'>
                        <div className="table-responsive">
                            <table
                                id="datatable-ecom"
                                ref={listtableRef}
                                className="data-tables table custom-table movie_table"
                            ></table>
                        </div>
                    </Card.Body>
                    <div className="card-footer text-end card-stretch card-height">
                        <Link to="#javascript:void(0);">
                            <span className="me-2">View all Settlements</span>{" "}
                            <svg
                                width="20"
                                className="icon-20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.5 5L15.5 12L8.5 19"
                                    stroke="currentColor"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></path>
                            </svg>
                        </Link>
                    </div>
                </Card>
            </Col>
        </Row>
    )
}

export default EventReports
