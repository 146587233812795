//react
import React, { Fragment, memo } from "react";
//react-bootstrap
import { Nav, Navbar, Container, Dropdown, Image } from "react-bootstrap";

//react-router
import { Link, useLocation } from "react-router-dom";

//components
import HorizontalNav from "./horizontal-nav";
import LandingOffcanvasHeader from "./landing-offcanvas-header";
import CustomToggle from "../../../../../../components/dropdowns";
import Logo from "../../../../../../components/partials/components/logo";

const Header1 = memo(() => {
  let location = useLocation();
  return (
    <Fragment>
      <Navbar
        expand="xl"
        className="nav navbar-expand-xl navbar-light iq-navbar header-hover-menu default"
      >
        <Container fluid className="navbar-inner">
          <div className="d-flex align-items-center justify-content-between w-100 landing-header">
            <HorizontalNav />
            <div className="d-flex align-items-center d-xl-none">
              <LandingOffcanvasHeader />
              <Link to="home" className="navbar-brand ms-3">
              {/* <Image src={'https://getyourticket.in/wp-content/uploads/2024/07/gyt-300x133.png'} alt="logo" loading="lazy" width={120}/> */}
                {/* <h5 className="logo-title">Get Your Ticket</h5> */}
              </Link>
            </div>
            <ul className="d-block d-xl-none list-unstyled m-0">
              <Dropdown as="li" className="nav-item iq-responsive-menu ">
                <Dropdown.Toggle as={CustomToggle} variant="btn btn-sm bg-body">
                  <svg
                    className="icon-20"
                    width="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="11.7669"
                      cy="11.7666"
                      r="8.98856"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></circle>
                    <path
                      d="M18.0186 18.4851L21.5426 22"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                </Dropdown.Toggle>
                <Dropdown.Menu
                  as="ul"
                  className="dropdown-menu-end"
                  style={{ width: "18rem" }}
                >
                  <li className="px-3 py-0">
                    <div className="form-group input-group mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search..."
                      />
                      <span className="input-group-text">
                        <svg
                          className="icon-20"
                          width="20"
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="11.7669"
                            cy="11.7666"
                            r="8.98856"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></circle>
                          <path
                            d="M18.0186 18.4851L21.5426 22"
                            stroke="currentColor"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></path>
                        </svg>
                      </span>
                    </div>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
            <Link
              to="/"
              className=" navbar-brand m-0 d-xl-flex d-none"
            >
              <Logo/>
              {/* <h5 className="logo-title">Get Your Ticket</h5> */}
            </Link>
            <Nav
              as="ul"
              className=" navbar-nav  align-items-center navbar-list d-none d-xl-flex"
            >
              <Nav.Item as="li">
                <Link
                  className={`${
                    location.pathname === "/landing-pages/blog" ? "active" : ""
                  } nav-link `}
                  to="/landing-pages/blog"
                >
                  Blog
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  className={`${
                    location.pathname === "/landing-pages/shop" ? "active" : ""
                  } nav-link `}
                  to="/landing-pages/shop"
                >
                  {" "}
                  Shop
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  className={`${
                    location.pathname === "/landing-pages/faq" ? "active" : ""
                  } nav-link `}
                  to="/landing-pages/faq"
                >
                  Faq
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  className={`${
                    location.pathname === "/landing-pages/contact-us"
                      ? "active"
                      : ""
                  } nav-link `}
                  to="/landing-pages/contact-us"
                >
                  Contact Us
                </Link>
              </Nav.Item>
              <Nav.Item as="li">
                <Link
                  className={`${
                    location.pathname === "/auth/sign-in"
                      ? "active"
                      : ""
                  } nav-link `}
                  to="/auth/sign-in"
                >
                  Login
                </Link>
              </Nav.Item>
            </Nav>
          </div>
        </Container>
      </Navbar>
    </Fragment>
  );
});

export default Header1;
