import React, { Fragment, memo, useState } from "react";
import { Nav, Offcanvas, Container, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CustomMenu from "./CustomMenu";

const LandingOffcanvasHeader = memo(() => {
  // const { isMobile }
  const [open, setOpen] = useState(false);
  let location = useLocation();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);
  return (
    <Fragment>
      <button
        data-trigger="navbar_main"
        className="d-xl-none btn btn-primary rounded-pill p-1 pt-0"
        type="button"
        onClick={handleShow}
      >
        <svg width="20px" height="20px" viewBox="0 0 24 24" className="icon-20">
          <path
            fill="currentColor"
            d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"
          ></path>
        </svg>
      </button>
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="mobile-offcanvas nav navbar navbar-expand-xl hover-nav py-0"
        style={{
          background: 'linear-gradient(to bottom, #17132E 0%, rgba(23, 19, 46, 0.5) 100%)',
          backdropFilter: 'blur(10px)', // Adjust blur radius as needed
          WebkitBackdropFilter: 'blur(10px)', // For Safari
        }}
      >
        <Container fluid className="p-lg-0 ">
          <Offcanvas.Header closeButton className="px-0 mx-3">
            <Link to="home" className="navbar-brand ms-3">
            </Link>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="landing-header">
              <Nav
                as="ul"
                className="navbar-nav iq-nav-menu  list-unstyled"
                id="header-menu"
              >
                <CustomMenu handleClose={handleClose}/>
              </Nav>
            </div>
          </Offcanvas.Body>
        </Container>
      </Offcanvas>
    </Fragment>
  );
});

export default LandingOffcanvasHeader;
