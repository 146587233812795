import React, { memo, Fragment } from "react";

// react-bootstrap
import { Col, Row, Container, Image, ListGroup } from "react-bootstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const AboutUs = memo(() => {
    return (
        <Fragment>
            <div className="section-padding">
                <Container>
                    <div className="d-flex justify-content-center align-items-center">
                        Details Will Be Updated Soon
                    </div>
                    {/* <Row className="align-items-center mb-5">
                        <Col md={9} className="mb-4 mb-md-0">
                        <h2 className=" mb-5">
                                Founder’s {" "}
                                <span className="text-primary">
                                    Words
                                </span>
                            </h2>
                            <p>
                                It's truly an honor representing Sevak Education & Charitable Trust. Back in 2013, this
                                journey began with a simple dream – to empower underprivileged children with the tools
                                they need to succeed in education.<br/><br/>
                                We started small, with just 15 children. But with unwavering dedication and the support
                                of our incredible community, Sevak has grown into a force for good. Today, we stand over
                                51,000 strong, with a network of more than 1,000 volunteers, 1,00,000+ guardians
                                entrusting us with their children's education, and over 400,000 lives touched by our
                                mission.<br/><br/>
                                Our mission remains the same: to bridge the gap in educational opportunities by providing
                                essential resources like notebooks, textbooks, and stationery kits – free of charge.
                                We believe that every child deserves a chance to learn and grow, regardless of their
                                background.<br/><br/>
                                Over the years, we've witnessed countless success stories – children who once struggled in
                                school are now thriving academically, pursuing their dreams with newfound confidence.
                                It is their stories that fuel our passion and inspire us to keep going.
                                However, the need for educational equity remains significant. This fundraising event is
                                crucial in allowing us to reach even more children and equip them for a brighter future.
                                I urge you to join us on this journey. Together, through your support, we can continue
                                to empower children, strengthen families, and build a more educated future for Gujarat.
                            </p>
                        </Col>
                        <Col md={3} className=" mt-4 mt-md-0">
                            <Image src={Image4} alt="" className="img-fluid " width={200} />
                        </Col>
                    </Row> */}
                    {/* <Row className="align-items-center mb-5">
                        <Col md={12} className="mb-4 mb-md-0">
                            <h2 className=" mb-5">
                                Motive of the{" "}
                                <span className="text-primary">
                                    Event
                                </span>
                            </h2>
                            <p>
                                For the past eleven years, Sevak Education & Charitable Trust has been
                                on a mission to empower underprivileged students across Gujarat with the
                                tools they need to succeed. Through our free educational kit distribution
                                program, we've reached over 51,000 students in cities like Surat,
                                Ahmedabad, Vadodara, Navsari, and Bardoli.<br/><br/>
                                Today, we're here to celebrate those students and continue our mission.
                                This fundraising event will allow us to distribute even more educational
                                kits, ensuring every child has the resources they deserve.
                                Join us in the festival’s nine nights are dedicated to different aspects of
                                the divine feminine principle, or Shakti.<br/><br/>
                                However, the first third of the festival focuses on aspects of Goddess
                                Durga, the second third on Goddess Lakshmi, and the final third on
                                Goddess Sarasvati.<br/><br/>
                                Together, we can make a difference in the lives of countless students
                            </p>
                        </Col>
                    </Row> */}
                </Container>
            </div>
        </Fragment>
    );
});
AboutUs.displayName = "AboutUs";
export default AboutUs;
