import React, { Fragment, memo } from "react";
import { Container, Row } from "react-bootstrap";


import LiveEvents from "../Events/LandingEvents/LiveEvents";



const Events = memo(() => {
  return (
    <Fragment>
      {/* <SubHeader title={"Events"} /> */}
      <div className="mt-4">
        <Container fluid className="px-5">
          <Row>
            <LiveEvents />
          </Row>
        </Container>
      </div>
    </Fragment>
  );
});

export default Events;
