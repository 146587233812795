import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { useMyContext } from '../../../../../Context/MyContextProvider';


const RazorPay = () => {
  const { api, successAlert, UserData, ErrorAlert,authToken } = useMyContext();
  const [razorpayKey, setRazorpayKey] = useState('');
  const [razorpaySecret, setRazorpaySecret] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async () => {
    try {
      await axios.post(`${api}store-razorpay`, {
        user_id: UserData?.id,
        razorpay_key: razorpayKey,
        razorpay_secret: razorpaySecret,
        status,
      }, {
        headers: {
          'Authorization': 'Bearer ' + authToken,
        }
      });
      successAlert('Success', 'Razorpay credentials stored successfully!');
    } catch (error) {
      ErrorAlert('Error', 'Failed to store Razorpay credentials.!');
    }
  };

  useEffect(() => {
    
  }, []);

  return (
    <Form>
      <Row>
        <Col lg="6">
          <Form.Group className="mb-3 form-group">
            <Form.Label className="custom-file-input">Razorpay Key</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={razorpayKey}
              onChange={(e) => setRazorpayKey(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Group className="mb-3 form-group">
            <Form.Label className="custom-file-input">Razorpay Secret</Form.Label>
            <Form.Control
              type="text"
              placeholder=""
              value={razorpaySecret}
              onChange={(e) => setRazorpaySecret(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col lg="6">
          <Form.Label className="custom-file-input">Status</Form.Label>
          <Row>
            <Col lg="6" className='d-flex gap-3'>
              <div className="form-radio form-check">
                <Form.Check.Input
                  type="radio"
                  id="enableRadio"
                  name="payment"
                  className="me-2"
                  value="Enable"
                  checked={status === 'Enable'}
                  onChange={(e) => setStatus(e.target.value)}
                />
                <Form.Label
                  className="custom-control-label"
                  htmlFor="enableRadio"
                >
                  Enable
                </Form.Label>
              </div>
              <div className="form-radio form-check">
                <Form.Check.Input
                  type="radio"
                  id="disableRadio"
                  name="payment"
                  className="me-2"
                  value="Disable"
                  checked={status === 'Disable'}
                  onChange={(e) => setStatus(e.target.value)}
                />
                <Form.Label
                  className="custom-control-label"
                  htmlFor="disableRadio"
                >
                  Disable
                </Form.Label>
              </div>
            </Col>
          </Row>
        </Col>
        <div className='d-flex justify-content-end'>
          <Button type="button" onClick={handleSubmit}>Submit</Button>
        </div>
      </Row>
    </Form>
  );
};

export default RazorPay;